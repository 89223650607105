import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { DeAppConfig } from '@de/auth';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(public router: Router, 
    //private config: DeAppConfig
    ) {
  }

  ngOnInit() {
    this.navigateToDashboard();
    }

    navigateToDashboard() {

    }

    onReload() {
     // window.location.replace(this.config.getConfig('azure_redirect_uri'));
    }

}
