import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-community-impact',
    templateUrl: './community-impact.component.html',
    styleUrls: ['./community-impact.component.scss']
})
export class CommunityImpactComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {

    }
}
