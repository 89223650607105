import { Component, OnInit } from '@angular/core';
import { ApiService, AccountService, AuthenticationService } from 'src/app/services';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IneligibilityReasonsDialogComponent } from './ineligibility-reasons-dialog/ineligibility-reasons-dialog.component';
import _ from 'lodash';
import { StepperService } from 'src/app/services/stepper.service';
import { Router } from '@angular/router';
import { CustomerSearchCriteria } from 'src/app/modules/enrollment/models/data/customer-search-criteria.model';
import { CancelEnrollmentDialogComponent } from './cancel-enrollment-dialog/cancel-enrollment-dialog.component';
import { AllocationStatus } from 'src/app/modules/shared/models/enum.model';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-verified-accounts',
  templateUrl: './verified-accounts.component.html',
  styleUrls: ['./verified-accounts.component.scss']
})
export class VerifiedAccountsComponent implements OnInit {
  public ineligibilityReasonsDialogRef: MatDialogRef<IneligibilityReasonsDialogComponent>;
  public cancelEnrollmentDialogRef: MatDialogRef<CancelEnrollmentDialogComponent>;
  public selectedAccountNumbers = [];
  public loggedIn: boolean;
  public customerSearchCriteria: CustomerSearchCriteria;
  public listOfAccountInformation = [];
  public isInRSP = false;
  public isLoading = true;
  public isMobile = false;

  constructor(private accountService: AccountService,
    public fakeApi: ApiService,
    public authService: AuthenticationService,
    public router: Router,
    public stepperService: StepperService,
    private windowRef: WindowReferenceService,
    public dialog: MatDialog) {
    this.isInRSP = this.accountService.getIsInRSPMode();
  }

  ngOnInit() {
    this.isMobile =  window.innerWidth < 601;
    this.isLoading = false;
    this.listOfAccountInformation = this.accountService.getAccounts();
    this.pushPageLoadGA();
    // Add this in when testing (Do NOT commit this!)
    // this.listOfAccountInformation.forEach(x => {
    //   x.eligibility.is_eligible = true;
    // })
  }

  showIneligibilityReasons(ineligibleAccount) {
    this.ineligibilityReasonsDialogRef = this.dialog.open(IneligibilityReasonsDialogComponent, {
      id: 'ineligibilityReasonsDialog',
      minWidth: '60vw',
      data: {
        account: ineligibleAccount,
        loggedIn: this.loggedIn
      }
    });
  }

  showCancelEnrollment(allocation, account) {
    this.cancelEnrollmentDialogRef = this.dialog.open(CancelEnrollmentDialogComponent, {
      id: 'cancelEnrollmentDialog',
      minWidth: '60vw',
      minHeight: '60vh',
      data: {
        allocation,
        account
      }
    });
  }

  public onAccountCheck(account, checked) {
    if (checked) {
      // Allows for only one account selection at a time
      this.selectedAccountNumbers = [];
      this.selectedAccountNumbers.push(account.account_number);
    } else {
      _.remove(this.selectedAccountNumbers, function (account_number) {
        return account_number === account.account_number;
      });
    }
  }

  public selectedAccount(accountNumber) {
    return this.selectedAccountNumbers.indexOf(accountNumber) > -1;
  }

  showEdit(allocation_status_id) {
    return (+allocation_status_id == 2 || +allocation_status_id == 4 ||
     +allocation_status_id == 3 || +allocation_status_id == 5);
  }

  navigateToModifyWaitlist(account: any, allocation) {
    this.accountService.setSelectedAccountNumber(account.account_number);
    this.router.navigate(['/enrollment/modify-waitlist'], { queryParams: { id: allocation.allocation_id } });
  }

  navigateToSubOptions() {
    const selectedAccountArray = _.filter(this.listOfAccountInformation, (account) => {
      return _.includes(this.selectedAccountNumbers, account.account_number);
    });
    this.accountService.setSelectedAccountNumber(selectedAccountArray[0].account_number);
    this.router.navigate(['/enrollment/subscription-options']);
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  onCancelClick() {
    this.router.navigate(['/enrollment']);
  }

  getStatus(account) {
    if(account.allocations.length == 0) {
      return "Not Enrolled";
    } else { 
      return account.allocations[0].allocation_status_description
    }
  }

  canMakeNewAllocation(account) {
    // Can only make a NEW allocation if there's not any live allocations in our system (not cancelled/verification failed)...
    // If we do have any of those, they could view/edit those - but not make new ones, which is what this method is for.
    // Additional enrollments would happen in the Customer Portal, not here on the Enrollment Tool.
    // Of course, they also have to be eligible.
    return account.eligibility.is_eligible === true && (account.allocations.find(
      allo => allo.allocation_status_id == AllocationStatus.Enrolled
      || allo.PendingEnrollment == AllocationStatus.PendingEnrollment || allo.allocation_status_id == AllocationStatus.Waitlist
      || allo.allocation_status_id == AllocationStatus.RSPRegistered || allo.allocation_status_id == AllocationStatus.AwaitingVerification
    ) == null);
  }

  pushPageLoadGA() {
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':`/vpv/cec/${registrationType}/verify-accounts/tab-load`});
  }
}
