import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

class InputData {
  account: any;
  loggedIn: boolean;
}

@Component({
  selector: 'app-ineligibility-reasons-dialog',
  templateUrl: './ineligibility-reasons-dialog.component.html',
  styleUrls: ['./ineligibility-reasons-dialog.component.scss']
})
export class IneligibilityReasonsDialogComponent {

  public account;
  public loggedIn;

  constructor(
    private ineligibilityDialogRef: MatDialogRef<IneligibilityReasonsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData) {
    this.ineligibilityDialogRef.disableClose = true;
    this.account = data.account;
    this.loggedIn = data.loggedIn;
  }

  // Close modal window...
  closeModal() {
    this.ineligibilityDialogRef.close();
  }
}
