import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { SelectedSubscription } from 'src/app/modules/enrollment/models/data/selected-subscription.model';
import { AccountService, AuthenticationService } from 'src/app/services';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.scss']
})
export class EditSubscriptionComponent implements OnInit, AfterViewInit {
  public account: any;
  public panelExpanded = true;
  public startingkW = 2;
  public allocationStatus: number;

  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  constructor( public accountService: AccountService,
    public router: Router,
    public authService: AuthenticationService,
    private windowRef: WindowReferenceService
  ) { }

  ngOnInit(): void {
    if(this.accountService.getSelectedAccountNumber() == null){
      this.router.navigate(['/customer-portal']);
    }
    this.account = this.accountService.getSelectedAccount();
    this.startingkW = this.accountService.getSelectedAllocation().allocation_size_kw;
    this.allocationStatus = this.accountService.getSelectedAllocation().allocation_status_id;
  }
   ngAfterViewInit() {
    setTimeout(x => {
      this.onPanelOpened();
    }, 100);
   }

  onPanelOpened() {
    this.panelExpanded = true;
  }

  onPanelClosed() {
    this.panelExpanded = false;
  }

  openPanel() {
    this.expansionPanel.open();
  }

  closePanel() {
    this.expansionPanel.close();
  }

  pushOnSaveGA(){
      this.windowRef.nativeWindow.dataLayer.push({
        'event-category': 'solar-subscription_subscriptions', 
        'event-action':'save',
        'event-label':'calculate-savings-and-benefits',
        'event':'event-click'
      });
  }

  onSave(event: any) {
  
    if(this.authService.getAdminLoggedIn()) { //Temp till i figure where to put this
      return;
    }
      // TODO: whole page needs to implement multiple accounts selected and pass on respective account instead of first listed...
      const selectedSubscription: Array<SelectedSubscription> = [{
        associatedAccount: this.account,
        metrics: event,
        allocationId: this.accountService.getSelectedAllocation().allocation_id
      }];
  
      this.accountService.setSelectedSubscriptions(selectedSubscription);
      this.pushOnSaveGA();
      // this.router.navigate(['/enrollment/terms-and-conditions', {From: 'Customer-Portal'}]);
    }
}
