import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { DeLoginComponent, HeaderLink } from '@de-electron/electron-angular-components';
import { AccountService, ApiService, AuthenticationService } from 'src/app/services';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { forkJoin } from 'rxjs';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public headerLinks: HeaderLink[] = [];
  public isMobile = false;
  public siteTitle = 'Clean Energy Connection Dashboard';
  public mobileSiteTitle = "";
  public selectedAccount: any;
  public accountOptions = [];
  public disableSelect = true;
  public loginDialogRef: MatDialogRef<DeLoginComponent>;
  public loggedIn;
  public listOfAccountInformation = [];

  public isLoginAllowed = false;
  public hasOne;
  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;

  constructor(public router: Router,
    public accountService: AccountService,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private windowRef: WindowReferenceService,
    private api: ApiService) { }

  ngOnInit(): void {
    this.api.getLoginKillSwitchStatus().subscribe(resp=> {
      this.isLoginAllowed = resp.show_login_boxes;
    })
  }

  loginUser(event) {
    this.loggedIn = this.authService.loggedIn.getValue();
    if (!this.loggedIn) {
      this.pushLoginLoadGA();
      const preRequestUrl = this.router.url;
      this.api.login(event.username, event.password).toPromise().then(async (resp: any) => {
        // // My Account Authentication Success, fetch customer accounts
        if (!resp.related_accounts || resp.related_accounts.length == 0) {
          location.reload();
          throw Error;
        }
        // My Account Authentication Success, fetch customer accounts
        this.accountService.setCDPId(resp.cdp_id);
        this.accountService.setRelatedAccounts(resp.related_accounts);
        let subscriptionList = [];
        resp.related_accounts.forEach(x => {
          subscriptionList.push(this.api.getCustomerLookup(x, null, this.accountService.getCDPId()))
        });
        forkJoin(subscriptionList).subscribe(respAccounts => {
          respAccounts.forEach(ra => {
            if (this.listOfAccountInformation.findIndex(loai => { return loai.account_number == (<any>ra).account_number }) < 0) {
              this.listOfAccountInformation.push(ra);
            }
          });
          this.accountService.setAccounts(this.listOfAccountInformation);
          this.authService.setUserRole('cust');
          this.setAccounts();
          this.router.navigate(['/customer-portal']);
        });
      }).catch((loginError: HttpErrorResponse) => {
        if ((<any>loginError.statusText) instanceof HttpErrorResponse) {
          // instance.customErrorMessage = (<any>loginError.statusText).statusText;
        } else {
          // instance.customErrorMessage = loginError.statusText;
        }
        this.pushErrorGA();
      });
      // });
    } else {
      this.setAccounts();
      // this.logOut();
    }
  }

  setAccounts() {
    this.accountOptions = this.accountService.getAccounts();

    if (this.accountOptions !== null) {
      this.disableSelect = false;
      this.selectedAccount = this.accountOptions.find(acct => acct.allocations.find(allo => allo.allocation_status_description === 'Enrolled'));
      if (!this.selectedAccount) {
        this.selectedAccount = this.accountOptions[0];
        this.navigateTo('/enrollment/verified-accounts');
      }
      this.accountService.setCustomerSegmentId(this.selectedAccount.customer_segment.customer_segment_id);
      this.accountService.setSelectedAccountNumber(this.selectedAccount.account_number);
    } else {
      this.navigateTo('/enrollment');
    }
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  pushLoginLoadGA() {
    this.windowRef.nativeWindow.dataLayer.push({ 'event': 'send-VPV', 'vpv-name': '/vpv/solar-subscription/sign-in/sign-into-the-clean-energy-connection-portal/page-load' });
  }

  pushErrorGA() {
    this.windowRef.nativeWindow.dataLayer.push({
        'event-category': 'solar-subscription_sign-in-error',
        'event-action': 'my-account-login-authentication-failure',
        'event-label': 'sign-into-the-clean-energy-connection-portal',
        'event': 'event-click'
      });
      this.windowRef.nativeWindow.dataLayer.push({ 'event': 'send-VPV', 'vpv-name': '/vpv/solar-subscription/sign-in/sign-into-the-clean-energy-connection-portal/error' })
  }
}