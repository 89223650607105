<div class="main-headings">
    <span class="close" (click)="closeModal()">&times;</span>
    <h1>
        Cancel <span *ngIf="isWaitlisted">Enrollment </span> Request
    </h1>
    <p *ngIf="!waitlistCanceled">
        Are you sure you wish to cancel the following <span *ngIf="isWaitlisted">waitlist</span> subscription?
    </p>
    <p class="pl-5 pr-5 ml-5 mr-5">
        If you decide to cancel, the account will be ineligible to participate in the program for 12 months. After that,
        you will be able to re-enroll, if capacity permits. Accounts that reenroll will follow year 1 credit and fee as
        defined in the tariff.
    </p>
</div>
<div class="statistics-container">
    <ng-container *ngIf="waitlistCanceled">
        <div class="successfully-cancelled">
            <h1>Your Subscription For {{account.account_number | maskAccountNumberPipe}} Has Been Successfully Cancelled.</h1>
        </div>
    </ng-container>
    <div *ngIf="!isLoading && subscriptionStats !== undefined && !waitlistCanceled" class="statistics-wrapper">
        <app-solar-subscription-card [cardTitle]="'Subscription for ' + account.account_number"
            [customSubscription]="false" [accountNumber]="account.account_number" [resNonRes]="'R'"
            [showMaxAndAddress]='false' [metrics]="subscriptionStats" [account]="account">
        </app-solar-subscription-card>
    </div>
    <div *ngIf="isLoading">
        <de-loader></de-loader>
    </div>
</div>
<div class="row justify-content-center align-center text-center col-md-8 offset-md-2" *ngIf="!waitlistCanceled">
    <mat-label>Please provide the reason for canceling your enrollment from the waitlist.</mat-label>
    <mat-form-field class="w-100 p-1">
        <mat-select floatLabel="never" [(value)]="selectedReason"
            (selectionChange)="onSelectCancelReason($event)">
            <mat-option [value]="0">- Select Reason -</mat-option>
            <mat-option [value]="17">Anticipated program cost too high</mat-option>
            <mat-option [value]="18">Been on the waitlist too long</mat-option>
            <mat-option [value]="19">Installing on-site solar</mat-option>
            <mat-option [value]="20">Program benefits are not compelling</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="cancel-button-container" *ngIf="!waitlistCanceled">
    <de-button type="primary" [disabled]="cancelLoading || selectedReason === 0" [loading]="cancelLoading" (click)="cancelWaitlist()">
        Cancel Subscription
    </de-button>
</div>
<div class="row justify-content-center pt-5" *ngIf="waitlistCanceled">
    <de-button type="primary" (click)="closeModal()">OK</de-button>
</div>