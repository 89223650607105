import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Pipe({
  name: 'trimAfterAlertPipe',
})
export class TrimAfterAlertPipe implements PipeTransform {
  constructor(public authService: AuthenticationService) {
  }
  // The purpose of this pipe is to only show 30 characters of a message
  transform(value: string): string {
    return value.slice(0, 30);
  }
}
