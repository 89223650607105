import { Component, OnInit } from '@angular/core';
enum page {
  Eligibility = 1,
  Verified = 2,
  Subscription = 3,
  Details = 4,
  Overview = 5
}
@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  currentPage: page;

  constructor() { }

  ngOnInit() {
      this.currentPage = page.Verified;
  }

  onDotClick(moveToPage: number) {
    if (moveToPage < this.currentPage) {
      this.currentPage = moveToPage;
    }
  }
}
