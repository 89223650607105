import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { AccountService, ApiService} from 'src/app/services';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CreditExampleDialogComponent } from './credit-example-dialog/credit-example-dialog.component';
import { BehaviorSubject } from 'rxjs';

const INPUT_MODE = {
  'MAX_ENROLLMENT_FEE': 'Max Enrollment Fee',
  'SUBSCRIPTION_SIZE': 'Subscription Size',
  'LOWEST_MONTHLY_CREDIT': 'Lowest Monthly Credit'
} as const;

const LOWEST_KW_SUBSCRIPTION = 1;

@Component({
  selector: 'app-solar-subscription-card',
  templateUrl: './solar-subscription-card.component.html',
  styleUrls: ['./solar-subscription-card.component.scss']
})
export class SolarSubscriptionCardComponent implements OnInit, AfterViewInit {
    @Input() inputMode: string;
    @Input() cardTitle: string;
    @Input() customSubscription: boolean;
    @Input() maxSubscription: number;
    @Input() accountNumber: number;
    @Input() metrics: any;
    @Input() resNonRes: string;
    @Input() showMaxAndAddress = true;
    @Input() account: any;


    @Output() cardSelected = new EventEmitter();

    private matchInput: ElementRef;
    // No setup needed since it is visible and will be set in afterViewInit

    // Input setup for subscription size input since it is initially hidden
    private subSizeInput: ElementRef;

    // Input setup for enrollment fee input since it is initially hidden
    private enrollmentFeeInput: ElementRef;
    private optionSelection = 1;

    private maxEnrollmentFee: number;
    private minEnrollmentFee: number;
    private minMonthlyCredit: number;
    private monthlyKWFee: number;

    public creditExampleDialogRef: MatDialogRef<CreditExampleDialogComponent>;

    public cardMetrics: any;
    public lowerBound = '1%';
    public upperBound = '100%';
    public selectedAccount: any;

    public selected = false;
    public loading$ = new BehaviorSubject(true);

    subscriptionCardForm: FormGroup = new FormGroup({
      matchInput: new FormControl(''),
      subSizeInput: new FormControl(''),
      enrollmentFeeInput: new FormControl('')
    });

  constructor( public apiService: ApiService,
    public accountService: AccountService,
    public dialog: MatDialog) {
      // Default metrics as failsafe
    }

  ngOnInit() {
    this.selectedAccount = this.account;
    if (this.cardTitle === undefined) {
      this.cardTitle = 'Subscription';
    }

    // this.customSubscription ? this.customCardInit() : this.stubCardInit();
  }

  ngAfterViewInit() {
    if (this.customSubscription) {
      this.matchInput.nativeElement.value = this.subscriptionCardForm.controls['matchInput'].value;
    }

  }

  // customCardInit() {
  //   // Default mode and/or title if none given
  //   INPUT_MODE[this.inputMode] ? this.inputMode = INPUT_MODE[this.inputMode]
  //   : this.inputMode = INPUT_MODE.SUBSCRIPTION_SIZE;

  //   // Fetch fee per kW based on account type
  //   //this.monthlyKWFee = this.resNonRes === 'R' ? JSON.parse(this.jurisdictionService.program).residential_fee
  //    // : JSON.parse(this.jurisdictionService.program).non_residential_fee;

  //   // Calculate initial metrics
  //   let initialCalculations$: Observable<Object>;

  //   let subscriptionSize: number;
  //   // Calculate upper and lower bounds for calculations
  //   if (this.initialKW !== undefined) {
  //     subscriptionSize = this.initialKW;
  //     initialCalculations$ = forkJoin([
  //       //this.apiService.calculateKwStats(this.maxSubscription, this.resNonRes, this.accountNumber),
  //       //this.apiService.calculateKwStats(LOWEST_KW_SUBSCRIPTION, this.resNonRes, this.accountNumber),
  //       //this.apiService.calculateKwStats(this.initialKW, this.resNonRes, this.accountNumber)
  //     ]);
  //   } else {
  //     subscriptionSize = this.maxSubscription;
  //     initialCalculations$ = forkJoin([
  //       this.apiService.calculateKwStats(this.maxSubscription, this.resNonRes, this.accountNumber),
  //       this.apiService.calculateKwStats(LOWEST_KW_SUBSCRIPTION, this.resNonRes, this.accountNumber)
  //     ]);
  //   }

  //   // Subscribe to initial calculated Observables
  //   initialCalculations$.subscribe((calculationsResponses: any) => {
  //     // Response object will be an Array if multiple requests sent or single object for one
  //     if (calculationsResponses[2] !== undefined) {
  //       //this.setNewMetrics(subscriptionSize, calculationsResponses[2].calculations);
  //     } else {
  //       //this.setNewMetrics(subscriptionSize, calculationsResponses[1].calculations);
  //     }
  //     // Set the min and max bounds for enrollment fee and monthly credit
  //    // this.maxEnrollmentFee = calculationsResponses[0].calculations.fees.enrollment_fee;
  //     //this.minEnrollmentFee = calculationsResponses[1].calculations.fees.enrollment_fee;
  //     //this.minMonthlyCredit = calculationsResponses[1].calculations.monthly_est_avg_credit_after_fees;

  //     this.loading$.next(false);
  //   });
  //   this.subscriptionCardForm.controls['matchInput'].setValue(Math.round((subscriptionSize / this.maxSubscription * 100) / 25.0) * 25);
  //   if (this.inputMode === 'Max Enrollment Fee') {
  //     this.subscriptionCardForm.controls['enrollmentFeeInput'].setValue(subscriptionSize * this.monthlyKWFee);
  //   } else if (this.inputMode === 'Subscription Size') {
  //     this.subscriptionCardForm.controls['subSizeInput'].setValue(subscriptionSize);
  //   }
  // }

  // stubCardInit() {
  //   this.apiService.calculateKwStats(this.initialKW, this.resNonRes, this.accountNumber).subscribe((res: any) => {
  //     this.setNewMetrics(this.initialKW, res.calculations);
  //   });
  // }

  setNewMetrics(currentSubscriptionSize: number, calculations: any) {
    this.cardMetrics = {
      subscription_size: currentSubscriptionSize,
      app_fee: calculations.fees.application_fee,
      enroll_fee: calculations.fees.enrollment_fee,
      monthly_subscription_fee: calculations.fees.monthly_subscription,
      lifetime_or_monthly: calculations.environmental.monthly_or_lifetime,
      monthly_estimate: calculations.monthly_est_avg_credit_after_fees.toString(),
      lifetime_savings: calculations.est_lifetime_savings.toString(),
      ten_years_ahead: calculations.est_lifetime_year
    };
  }

  selectSubscription() {
    this.selected = !this.selected;
    this.cardSelected.emit(this.selected);
  }

  showCreditExample() {
    this.creditExampleDialogRef = this.dialog.open(CreditExampleDialogComponent);
  }

  getTextColor(number: string) {
    if((number + "").includes('-')) {
      return 'red-text';
    }
    return 'blue-text'
   }

  formatInput(value) {
    value = value.replace(/\D/g, '');
    if (this.optionSelection === 1) {
      if (value > parseInt(this.upperBound, 10)) {
        value = parseInt(this.upperBound, 10);
      }
      this.matchInput.nativeElement.value = value;
      return value;
    } else {
      if (this.inputMode === 'Subscription Size') {
        if (value > this.maxSubscription) {
          value = this.maxSubscription;
        }
        this.subSizeInput.nativeElement.value = value;
        return value;
      } else if (this.inputMode === 'Max Enrollment Fee') {
        if (value > parseInt(this.upperBound.substr(1), 10)) {
          value = parseInt(this.upperBound.substr(1), 10);
        }
        this.enrollmentFeeInput.nativeElement.value = value;
        return value;
      }
    }
  }


  calculateSubSize(subscriptionSize: number) {
    if (subscriptionSize < LOWEST_KW_SUBSCRIPTION) {
      subscriptionSize = LOWEST_KW_SUBSCRIPTION;
      this.subSizeInput.nativeElement.value = LOWEST_KW_SUBSCRIPTION;
    }
    const calculatedEnergyMatch = Math.round((subscriptionSize / this.maxSubscription) * 100);
    this.subscriptionCardForm.controls['subSizeInput'].setValue(subscriptionSize);
    this.subscriptionCardForm.controls['matchInput'].setValue(calculatedEnergyMatch);
    // this.apiService.calculateKwStats(subscriptionSize, this.resNonRes, this.accountNumber).subscribe((calculationRes: SubscriptionCardResponse) => {
    //   this.setNewMetrics(subscriptionSize, calculationRes.calculations);
    // });
  }

  calculateEnrollmentFee(enrollmentFee: number) {
    const lowerBoundNumber = parseInt(this.lowerBound.substr(1), 10);
    if (enrollmentFee < lowerBoundNumber) {
      enrollmentFee = lowerBoundNumber;
    }

    const calculatedKWAmount = Math.floor(enrollmentFee / this.monthlyKWFee);
    const calculatedEnergyMatch = Math.round((calculatedKWAmount / this.maxSubscription) * 100);
    this.subscriptionCardForm.controls['matchInput'].setValue(calculatedEnergyMatch);
    this.subscriptionCardForm.controls['enrollmentFeeInput'].setValue(enrollmentFee);
    // this.apiService.calculateKwStats(calculatedKWAmount, this.resNonRes, this.accountNumber).subscribe((calculationRes: SubscriptionCardResponse) => {
    //   this.setNewMetrics(calculatedKWAmount, calculationRes.calculations);
    // });
  }
}
