import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnrollmentRoutingModule } from './enrollment-routing.module';
import { EnrollmentComponent } from './components/enrollment/enrollment.component';
import { EnrollmentSummaryComponent } from './components/enrollment-summary/enrollment-summary.component';
import { ModifyWaitlistComponent } from './components/modify-waitlist/modify-waitlist.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { KwSliderComponent } from '../shared/kw-slider/kw-slider.component';
import { SavingsCalculatorComponent } from './components/savings-calculator/savings-calculator.component';
import { SolarSubscriptionCardComponent } from './components/solar-subscription-card/solar-subscription-card.component';
import { SubscriptionOptionsComponent } from './components/subscription-options/subscription-options.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { AllocationBreakdownComponent } from './components/verified-accounts/allocation-breakdown/allocation-breakdown.component';
import { CancelEnrollmentDialogComponent } from './components/verified-accounts/cancel-enrollment-dialog/cancel-enrollment-dialog.component';
import { IneligibilityReasonsDialogComponent } from './components/verified-accounts/ineligibility-reasons-dialog/ineligibility-reasons-dialog.component';
import { VerifiedAccountsComponent } from './components/verified-accounts/verified-accounts.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/token.interceptor';
import { AuthenticationService, ApiService, StepperService } from '../../services';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DeButtonModule,
  DeFooterModule,
  DeHeaderModule,
  DeStepperModule,
  DeRadioButtonModule,
  DeLoginModule,
  DeLoaderModule } from '@de-electron/electron-angular-components';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { SharedModule } from 'src/app/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LoginComponent } from './components/login/login.component';
import { AccountPanelComponent } from './components/subscription-options/account-panel/account-panel.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IncomeQualifiedComponent } from './components/income-qualified/income-qualified.component';
import { UploadSuccessComponent } from './components/upload-success/upload-success.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule,
    EnrollmentRoutingModule,
    PipeModule,
    MatCheckboxModule,
    MatExpansionModule,
    DeButtonModule,
    DeFooterModule,
    DeHeaderModule,
    DeStepperModule,
    DeRadioButtonModule,
    DeLoginModule,
    DeLoaderModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    SharedModule,
    NgxChartsModule,
    ReactiveFormsModule,
    MatSnackBarModule
  ],
  declarations: [
    EnrollmentComponent,
    ProgressBarComponent,
    VerifiedAccountsComponent,
    IneligibilityReasonsDialogComponent,
    TermsAndConditionsComponent,
    SubscriptionOptionsComponent,
    SolarSubscriptionCardComponent,
    SavingsCalculatorComponent,
    ModifyWaitlistComponent,
    AllocationBreakdownComponent,
    CancelEnrollmentDialogComponent,
    EnrollmentSummaryComponent,
    LoginComponent,
    AccountPanelComponent,
    IncomeQualifiedComponent,
    UploadSuccessComponent
  ],
  providers: [
    AuthenticationService,
    ApiService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    StepperService,
    AuthGuard
  ]
})
export class EnrollmentModule { }
