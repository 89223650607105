import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdinalPipe } from './ordinal-number.pipe';
import { FilterActiveOrCancelledPipe } from './filter-active-or-cancelled.pipe';
import { MaskAccountNumberPipe } from './mask-account-number.pipe';
import { TrimAfterAlertPipe } from './trim-after-alert.pipe';

@NgModule({
  declarations: [
    OrdinalPipe,
    FilterActiveOrCancelledPipe,
    MaskAccountNumberPipe,
    TrimAfterAlertPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OrdinalPipe,
    FilterActiveOrCancelledPipe,
    MaskAccountNumberPipe,
    TrimAfterAlertPipe
  ]
})
export class PipeModule { }
