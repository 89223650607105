<div class="container">
    <form [formGroup]="documentForm" novalidate>
        <div class="topDescriptionBox pt-5 pb-5">
            <div class="row pt-5 to-center">
                <div class="col to-center">
                    <h2>Enroll in Income-Qualified for Clean Energy Connection</h2>
                </div>
            </div>
            <div class="row pb-5 to-center">
                <div class="col to-center">
                    <h5>Learn more about the Income-Qualified Program. Including eligibility requirements.</h5>
                </div>
            </div>
        </div>
        <br>
        <div class="row pt-5 underlined mb-2">
            <div class="col pb-1">
                <h4>Please Complete</h4>
            </div>
        </div>
        <div class="row pt-5">
            <div class="col main-label">
                <h4>Type of Income-Qualified Document *</h4>
            </div>
        </div>
        <div class="row"><h4 class="longerWidth pl-4">
            <mat-form-field class="filter pl-4 longerWidth" floatLabel="never">
                <mat-select formControlName="typeOfDocument" class="long pl-4 longerWidth">
                    <mat-option [value]="'ssi'"><h4>SSI</h4></mat-option>
                    <mat-option [value]="'medicaid'"><h4>Medicaid</h4></mat-option>
                    <mat-option [value]="'tanf'"><h4>TANF</h4></mat-option>
                    <mat-option [value]="'snap_ebt'"><h4>SNAP/EBT</h4></mat-option>
                    <mat-option [value]="'liheap'"><h4>LIHEAP</h4></mat-option>
                    <mat-option [value]="'other'"><h4>Other</h4></mat-option>
                </mat-select>
            </mat-form-field>
        </h4>
        </div>
        <div class="row">
            <div class="col main-label">
                <h4>Income-Qualified User</h4>
            </div>
        </div>
        <div class="row"><h4 class="longerWidth pl-4">
            <mat-form-field class="filter pl-4 longerWidth" floatLabel="never">
                <mat-select formControlName="personToUse" class="long pl-4 longerWidth">
                    <mat-option *ngFor="let person of listOfAccounts; let i = index"
                    [(value)]="listOfAccounts[i].main_customer.full_name">
                    {{person.main_customer.full_name}}
                </mat-option>
                </mat-select>
            </mat-form-field>
        </h4>
        </div>
        <div class="row pt-5">
            <div class="col main-label">
                <h4>Income-Qualified Documentation</h4>
            </div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="col biggerText">If you currently participate in one of the following goverment assistance
                programs, please upload a photo of your documentation below. The image should meet these requirements.</div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col biggerText">Last name should match the name on your Duke Energy account.</div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col biggerText">The benefit should be approved within the last 12 months.</div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col to-left biggerText">Benefit programs not on the list below will be reviewed on a case-by-case basis.</div>
        </div>
        <div class="row pt-5 pb-2">
            <div class="col biggerText">Here are some of the goverment programs you can use to get verified for Clean Energy Connection.</div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col main-label biggerText"><b>Supplemental National Assistance Program (SNAP/EBT)</b></div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">&nbsp;</div><div class="col biggerText"> Uploads typically include one of the following:</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of your EBT Card</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Program approval/eligibility (dated within the last 12 months)</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of Access Florida website's benefit details page under "Food Assistance"</div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col main-label biggerText"><b>Medicaid</b></div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">&nbsp;</div><div class="col biggerText"> Uploads typically include one of the following:</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of your Medicaid Card</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Program approval/eligibility letter (dated within the last 12 months)</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of Access Florida website's benefit details page under "Medical Assistance"</div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col main-label biggerText"><b>LIHEAP (Low Income Home Energy Assistance Program)</b></div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">&nbsp;</div><div class="col biggerText"> Uploads typically include one of the following:</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of your approval/eligibility letter (dated within the last 12 months)</div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col main-label biggerText"><b>SSI (Supplemental Security Income)</b></div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">&nbsp;</div><div class="col biggerText"> Uploads typically include one of the following:</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of your approval/eligibility letter (dated within the last 12 months)</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of your SSI benefit verification letter from the Social Security Administration</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText italic"><u>Note: This is not the same as Social Security income or disability benefits.  Your approval letter must indicate you are eligible for “Supplemental Security Income”.</u></div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">•</div><div class="col main-label biggerText"><b>TANF (Temporary Assistance for Needy Families)</b></div>
        </div>
        <div class="row pt-1 pb-2">
            <div class="pl-4">&nbsp;</div><div class="col biggerText"> Uploads typically include one of the following:</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of your approval/eligibility letter (dated within the last 12 months)</div>
        </div>
        <div class="row pt-1 pb-2 pl-4">
            <div class="pl-5">•</div><div class="col biggerText"> Image of Access Florida website's benefit details page under "Temporary Cash Assistance"</div>
        </div>
        <div class="row pt-4 pb-4">
            <div class="col ml-4 biggerText">Please select a .jpeg .png .jpg .pdf .bmp or .gif to upload. (5MB maximum size)
            </div>
        </div>
        <de-button type="primary" size="medium" class="m-auto" (click)="onUploadFile()">
            Upload Document
        </de-button>   {{fileToSend?.name}}
        <input #fileUpload type="file" class="upload" (change)="changeListener($event.target.files)"
            style="display: none;">
        <div class="row pt-2 pb-4">
            <div class="col biggerText">If you do not participate in any of the above goverment assistance programs but
                you
                do meet the income requirements, please bring your proof of income to your nearest community agency.
                These
                orginizations verify income and administer assistance programs.</div>
        </div>
        <div class="row to-center">
            <div class="col to-center">
                <de-button type="secondary" size="medium" class="m-auto" (click)="onCancel()">
                    Cancel
                </de-button>
                <de-button type="primary" size="medium" class="m-auto" [disabled]="!this.documentForm.valid || !this.fileToSend" (click)="onSubmit()">
                    Submit
                </de-button>
            </div>
        </div>
        <br><br>
        <div class="row to-center med-font-size">
            <div class="col to-center"><p>Have a question? Read the Clean Energy Connection 
                <span><a href="https://desitecoreprod-cd.azureedge.net/_/media/pdfs/for-your-home/220969/cec-def-media-kit-faqs.pdf?la=en&rev=622ea6fd31a14d669c9dec8fd88a9784" target="_blank">FAQs</a></span>
                .</p></div>
        </div>
        <div class="row to-center med-font-size">
            <div class="col to-center"><p>If you have a question that cannot be answered using the website or FAQs, then please contact us at <a href="tel:8662332290">866.233.2290</a> or 
                <span> <a href = "mailto: CECQualified@duke-energy.com">CECQualified@duke-energy.com</a></span>.</p></div>
        </div>
        <br><br>
    </form>
</div>