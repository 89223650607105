import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerPortalRoutingModule } from './customer-portal-routing.module';
import { CustomerPortalComponent } from './customer-portal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar'
  
import { DeButtonModule,
  DeFooterModule,
  DeHeaderModule,
  DeStepperModule,
  DeRadioButtonModule,
  DeLoginModule,
  DeLoaderModule,
  DeSegmentedControlModule,
  DeSnackbarModule} from '@de-electron/electron-angular-components';

import { DocumentComponent } from './components/document/document.component';
import { ReportComponent } from './components/reports/report.component';
import { CommunityImpactComponent } from './components/community-impact/community-impact.component';
import { AlertComponent } from './components/alert/alert.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BillImpactIntervalComponent } from './components/dashboard/bill-impact-interval/bill-impact-interval.component';
import { SharedModule } from 'src/app/shared.module';
import { EditSubscriptionComponent } from './components/edit-subscription/edit-subscription.component';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardAlertComponent } from './components/dashboard/dashboard-alert/dashboard-alert.component';
import { AlertMessageDialogComponent } from './components/alert-message-dialog/alert-message-dialog.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
//import { AngularResizedEventModule } from 'angular-resize-event';
import { MonthlyBreakdownComponent } from './components/monthly-breakdown/monthly-breakdown.component';
import { SavingsCalculatorComponent } from './components/savings-calculator/savings-calculator.component';
import { SolarSubscriptionCardComponent } from './components/solar-subscription-card/solar-subscription-card.component';
import { CancelEnrollmentDialogComponent } from './components/cancel-enrollment-dialog/cancel-enrollment-dialog.component';
import { LoginComponent } from './components/login/login.component';


@NgModule({
  declarations: [CustomerPortalComponent,
    DocumentComponent,
    CommunityImpactComponent,
    AlertComponent,
    ReportComponent,
    DashboardComponent,
    EditSubscriptionComponent,
    DocumentComponent,
    AlertMessageDialogComponent,
    ReportComponent,
    BillImpactIntervalComponent,
    DashboardAlertComponent,
    MonthlyBreakdownComponent,
    SavingsCalculatorComponent,
    SolarSubscriptionCardComponent,
    CancelEnrollmentDialogComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    CustomerPortalRoutingModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatToolbarModule,
    DeButtonModule,
    DeFooterModule,
    DeHeaderModule,
    DeStepperModule,
    DeRadioButtonModule,
    DeLoginModule,
    DeLoaderModule,
    DeLoginModule,
    DeSegmentedControlModule,
    DeSnackbarModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    NgxChartsModule,
   // AngularResizedEventModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PipeModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    AlertMessageDialogComponent
  ]
})
export class CustomerPortalModule { }
