import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeLoginComponent, HeaderLink } from '@de-electron/electron-angular-components';
import { AccountService, ApiService, AuthenticationService} from 'src/app/services';
import { AccountIdViewAsResponse } from '../../models/account-id-view-as-response';

@Component({
  selector: 'app-customer-portal-admin',
  templateUrl: './customer-portal-admin.component.html',
  styleUrls: ['./customer-portal-admin.component.scss']
})
export class CustomerPortalAdminComponent implements OnInit {

  public headerLinks: HeaderLink[] = [];

  public siteTitle = 'Solar Subscription Dashboard - View As';

  public selectedAccount: any;
  public accountOptions = [];
  public disableSelect = true;
  public loginDialogRef: MatDialogRef<DeLoginComponent>;
  public loggedIn;
  public username;

  constructor(public router: Router,
    public accountService: AccountService,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private apiService: ApiService) {
      this.route.params.subscribe(params => {
        if (params['Username']) {
          this.username = params['Username'];
  }
       });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/admin') {
          this.siteTitle = 'Solar Subscription Dashboard - View As';
        } else if (event.urlAfterRedirects === '/admin/reports') {
          this.siteTitle = 'Your Solar Subscription History';
        } else if (event.urlAfterRedirects === '/admin/alerts') {
          this.siteTitle = 'Solar Subscription Alerts';
        } else if (event.urlAfterRedirects === '/admin/edit-subscription') {
          this.siteTitle = 'Modify Solar Subscription';
        } else if (event.urlAfterRedirects === '/admin/documents') {
          this.siteTitle = 'Solar Subscription View Documents';
        } else if (event.urlAfterRedirects === '/admin/bills') {
          this.siteTitle = 'Solar Subscription Monthly Breakdown';
        }
      }
    });

    authService.loggedIn.subscribe(logged => {
      this.loggedIn = logged;
    });
  }

  ngOnInit() {
    this.initializePortalForAdmin(this.username);
  }

  initializePortalForAdmin(lanId: string) {
    // Fetch account last clicked on (from Program Admin Tool) for logged-in employee...
    this.apiService.getAdminActAs(lanId).subscribe((response: any) => {
      if (response.account_id_view_as != null) {
        let accountNum = response.account_id_view_as;
        this.apiService.getCustomerLookup(accountNum, null, null).subscribe(x => {
          this.accountService.setAccounts([x]);      
          this.accountService.setSelectedAccountNumber(x.account_number);
          this.authService.setAdminLoggedIn(true);
          this.authService.setUserRole('cust');
        })
      } else {
       this.navigateTo('/unauthorized');
      }
    });
  }

  showPageContent(): boolean {
    return this.loggedIn && this.authService.getAdminLoggedIn();
  }

  setupHeader() {
    this.headerLinks = [
      {
        viewValue: 'Dashboard',
        value: 'dashboard',
        iconSvgHref: '#homeIconLink'
      },
      {
        viewValue: 'Menu',
        value: 'menu',
        showSubLinks: true,
        subLinks: [
          {
            viewValue: 'Alerts',
            value: 'alerts'
          },
          {
            viewValue: 'Edit Subscription',
            value: 'edit-subscription'
          },
          {
            viewValue: 'View Documents',
            value: 'documents'
          },
          {
            viewValue: 'Monthly Breakdown',
            value: 'bills'
          }
        ]
      }
    ];
  }

  setAccounts() {
    this.accountOptions = this.accountService.getAccounts();
    if (this.accountOptions !== null) {
      this.disableSelect = false;
      this.selectedAccount = this.accountOptions.find(acct => acct.allocations.find(allo => allo.allocation_status_description === 'Active'));
     // this.accountService.setSelectedAccounts([this.selectedAccount]);
    }
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  onAccountSelectionChange(event: MatSelectChange) {
    // No active enrollments for selected account, redirect to enrollment piece
    if (event.value.allocations.filter(allo => allo.allocation_status_description === 'Active').length === 0) {
      this.navigateTo('/enrollment/verified-accounts');
    } else {
      this.selectedAccount = event.value;
      //this.accountService.setSelectedAccounts([this.selectedAccount]);
    }
  }

  onHeaderNav(link: HeaderLink) {
    if (link.value !== 'menu') {
      this.setupHeader();
      this.router.navigate(['/admin/' + link.value]);
    }
  }

}
