export class BillImpactIntervalModel {
    interval_title: string;
    interval_time_period: string;
    energy: {
        solar_generation_kwh: number;
        usage_kwh: number;
    };
    dollars: {
        credits: number;
        fees: number;
        savings: number;
    };
    summary: {
        total_months: number;
        credits_avg: number;
        fees_avg: number;
        savings_avg: number;
        est_break_even_date: string;
    };
}
