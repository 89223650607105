<div class="solar-impact_heading pb-2">
    <div class="solar-impact_heading-title">
        <p>Alerts</p>
    </div>
</div>
<div class="container">
    <!-- <ng-container *ngIf="!alerts">
        <de-loader class="w-100"></de-loader>
    </ng-container> -->
    <ng-container *ngFor="let alert of alerts; let i = index">
        <div *ngIf="i<5" class="alert-row">
            <div class="row ml-4"><b>{{alert.alert_type | trimAfterAlertPipe }} Alert</b></div>
            <div class="row ml-4">{{alert.alert_message | trimAfterAlertPipe }}</div>
        </div>
    </ng-container>
</div>
<div class="text-center">
 <span><a href="javascript:;" (click)='onViewMessages();'>View Messages</a></span>
</div>
