import { Component, Input, OnInit } from '@angular/core';

import _ from 'lodash';
import { AppConfig } from 'src/app/config/app.config';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.scss']
})
export class DocumentTableComponent implements OnInit {
@Input() showChecks = true;
public documentList = [];
public jurisdiction = 'DEP'; //TODO: implement get Jurisdiction
public isMobile = false;

  constructor(public appConfig: AppConfig) { }

  ngOnInit() {
    this.isMobile =  window.innerWidth < 601;
    this.documentList = [{ link: this.jurisdiction.toLowerCase() === 'DEP' ? this.appConfig.getConfig('DEPTermsAndConditions') : this.appConfig.getConfig('DECTermsAndConditions'), 
    file_name: 'Clean Energy Connection Terms & Conditions',
    description: 'Terms and Conditions'
    }]
  }

  navigateTo(route) {
  }

}
