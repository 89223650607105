<div class="container card-body" [ngClass]="{'border-green': selected}">
    <div class="title-area" [ngClass]="{'color-green': selected}">
        <div class="row card-header-top-row">
            <div class="col">
                <b><span>{{cardTitle}}</span></b>
            </div>
            <div *ngIf="showMaxAndAddress" class="col content-center">
                <div class="row content-center">{{selectedAccount.premise_address.line1}}</div>
            </div>
            <div *ngIf="showMaxAndAddress && metrics" class="col">
                <div class="row content-right">{{metrics.subscription_size_kw}} kW requested of
                    {{selectedAccount.max_subscription_size_kw}}kW Max</div>
            </div>
        </div>
    </div>
    <div *ngIf="metrics" class="information-section">
        <div class="row">
            <div class="col">
                <div class="row content-center">
                    <h4><b>Monthly Fee</b></h4>
                </div>
                <div class="row content-center blue-text">
                    <h3>${{metrics.monthly_subscription_fee}}</h3>
                </div>
                <br>
                <div class="row content-center">
                    <h4><b>Average Monthly Credit(1st year)*</b></h4>
                </div>
                <div class="row content-center blue-text">
                    <h3>${{metrics.avg_monthly_credit_first_year}}</h3>
                </div>
                <br>
                <div class="row content-center">
                    <h4><b>Average Net Savings(1st year)*</b></h4>
                </div>
                <div class="row content-center" [ngClass]="getTextColor(metrics.avg_monthly_net_savings_first_year)">
                    <h3>${{metrics.avg_monthly_net_savings_first_year}}</h3>
                </div>
            </div>
            <div class="col left-border">
                <div class="row">&nbsp;</div>
                <div class="row">&nbsp;</div>
                <div class="row content-center">
                    <h4><b>Subscription Requested</b></h4>
                </div>
                <div class="row content-center blue-text">
                    <h3>{{metrics.subscription_size_kw}} kW</h3>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!metrics">
        <de-loader class="w-100 m-auto mt-5 mb-5 pt-5 pb-5"></de-loader>
    </div>
</div>