import {gql} from 'graphql-tag'

export const CUSTOMER_DETAIL = gql`
query  getCustomerLookup {
  getCustomerLookup {
    account_number
	  account_notes
  	customer_segment{
	    customer_segment_id
	    rsp_start_date
	    rsp_end_date
	    product_code
	    customer_segment_description
    }
  	business_partner_id
  	max_subscription_size_kw
    current_allocation_size_kw
    premise_address {
      line1
      city
      state
      zip
    }
    billing_address {
      line1
      city
      state
      zip
    }
    main_customer {
      full_name
      email
      phone
    }
    allocations {
      allocation_id
      account_id
      allocation_size_kw
      max_sub_kw
      allocation_status_id
      allocation_status_description
      created_at
      pending_date
      active_date
      cancelled_date
      cancel_reason_id
      enrollment_channel_id
    }
    eligibility {
      ineligibility_reasons
	    is_eligible
	    product_code
    }
    is_low_income
    is_residential
    email_updates_interval
    wait_list_position
  }
}
`;

export const SUBSCRIPTION_CARD = gql`
query SubscriptionCard{
  subscriptionCard{
    monthly_est_avg_credit_after_fees
    est_lifetime_savings
    est_lifetime_year
    environmental{
      co2_lbs_omitted
      trees_planted
      cars_avoided
      monthly_or_lifetime
    }
    fees{
      application_fee
      enrollment_fee
      monthly_subscription
    }
  }
}
`;

export const UPDATE_ENROLL_ACCOUNT = gql`
mutation UpdateEnrollAccount( 
  $account_number: String, 
  $source_system: String, 
  $business_partner_id: String, 
  $account_type_id: Int, 
  $is_low_income: Boolean,
  $account_lmi_verification_type_id: Int,
  $account_lmi_verification_date: String,
  $account_notes: String,
  $enrollment_channel_id: Int,
  $allocation_size_kw: Int,
  $max_subscription_size_kw: Int,
  $program_id: String,
  $facility_id: Int,
  $created_by: String
){
  updateEnrollAccount( 
      account_number: $account_number
      source_system: $source_system
      business_partner_id: $business_partner_id
      account_type_id: $account_type_id
      is_low_income: $is_low_income
      account_lmi_verification_type_id: $account_lmi_verification_type_id
      account_lmi_verification_date: $account_lmi_verification_date
      account_notes: $account_notes
      enrollment_channel_id: $enrollment_channel_id
      allocation_size_kw: $allocation_size_kw
      max_subscription_size_kw: $max_subscription_size_kw
      program_id: $program_id
      facility_id: $facility_id
      created_by: $created_by 
    
    )
    {
  message
  }
}
`;
