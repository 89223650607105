<div class="container" style="padding: unset">
    <div class="step-description">
        <h1>Select your Clean Energy Connection subscription amount</h1>
        <p>Please click on your account(s) below to select a subscription level for the program
        </p>
    </div>
    <div class="accounts-container">
        <ng-container>
            <app-account-panel [account]="selectedAccount">
            </app-account-panel>
        </ng-container>
    </div>
    <div class="button-container">
        <de-button (click)="navigateTo('/enrollment/verified-accounts')">PREVIOUS</de-button>
        <de-button type="continue" (click)="!accountService.getCurrentCalculatorSize() ? $event.preventDefault() : [navigateTo('/enrollment/terms-and-conditions'), confirmSubscription()]"
            [disabled]="!accountService.getCurrentCalculatorSize()">Save</de-button>
    </div>
    <span class="cancel-link" (click)="onCancelClick()">
        Cancel
    </span>
</div>
