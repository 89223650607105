import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-example-dialog',
  templateUrl: './credit-example-dialog.component.html',
  styleUrls: ['./credit-example-dialog.component.scss']
})
export class CreditExampleDialogComponent implements OnInit {
  public creditExample: any[] = [{
    month_year: 'JAN 19',
    energy_usage: 1200,
    electric_bill: 152.22,
    solar_generation: 320,
    sub_fee: 18.75,
    shared_solar_credit: 26.22,
    credit_after_fees: 7.47
  }];
  public creditTotal = 0;
  public creditAfterFeesTotal = 0;

  constructor() {
    for (let i = 0; i < 11; i++) {
      this.creditExample.push(this.creditExample[0]);
    }
    this.creditExample.forEach((creditRow) => {
      this.creditTotal += creditRow.shared_solar_credit;
      this.creditAfterFeesTotal += creditRow.credit_after_fees;
    });
  }

  ngOnInit(): void {
  }

}
