import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from 'src/app/modules/enrollment/models/data/alert.model';
import { AccountService, AuthenticationService } from 'src/app/services';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-dashboard-alert',
  templateUrl: './dashboard-alert.component.html',
  styleUrls: ['./dashboard-alert.component.scss']
})
export class DashboardAlertComponent implements OnInit {
  accountNumber: string;
  alerts: Alert[] = null;

  constructor(public accountSvc: AccountService,
     public router: Router,
     private windowRef: WindowReferenceService,
     public authService: AuthenticationService) { }

  ngOnInit(): void {
    this.getAlerts();
  }

  getAlerts() {
    this.accountSvc.selectedAccountNumber$.subscribe(accountNumber => {
      if (!accountNumber) {
        return;
      }
      this.loadAlerts();
    });
    if (!this.accountSvc.getSelectedAccount()) {
      return;
    }
    this.loadAlerts();
}

loadAlerts() {
  this.accountNumber = this.accountSvc.getSelectedAccountNumber();
  this.accountSvc.getMessages(this.accountNumber).subscribe(al => {
    this.alerts = al;
  });
}

navigateTo(route) {
  this.router.navigate([route]);
}

onViewMessages() {
  if (this.authService.getAdminLoggedIn()) {
    this.navigateTo('/admin/alerts');
  } else {
    this.pushOnViewMessagesGA();
    this.navigateTo('/customer-portal/alerts');
  }
}

pushOnViewMessagesGA(){
  this.windowRef.nativeWindow.dataLayer.push({
      'event-category':'solar-subscription_dashboard', 
      'event-action':'view-messages',
      'event-label':'alerts',
      'event':'event-click'
    });
}

}
