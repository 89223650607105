<de-header [isExternal]="true" [centerLogo]="true"></de-header>
<div class="stepper-container">
    <de-stepper #stepper [steps]="steps" [activeStep]="activeStep" [activeIncrement]="activeIncrement"></de-stepper>
</div>
<div class="account-level-message">
    <div class="account-level-message__text">
        <!-- <p class="account-level-message__text__content">Already enrolled? View your <a (click)="navigateTo('/customer-portal')">Clean Energy Connection Portal</a></p> -->
    </div>
</div>
<router-outlet></router-outlet>
<div class="row justify-content-center pt-5 col-6 offset-3">
    Have a question? Read the Clean Energy Connection <a class="ml-1"
        *ngIf="accountService.getCustomerSegmentId() === customerSegment.MarketRate"
        href="https://desitecoreprod-cd.azureedge.net/_/media/pdfs/for-your-home/220969/cec-def-media-kit-faqs.pdf?la=en&rev=622ea6fd31a14d669c9dec8fd88a9784"
        target="_blank">FAQs</a><a class="ml-1"
        *ngIf="accountService.getCustomerSegmentId() === customerSegment.IncomeQualified"
        href="https://desitecoreprod-cd.azureedge.net/_/media/pdfs/for-your-home/220969/cec-def-media-kit-faqs.pdf?la=en&rev=622ea6fd31a14d669c9dec8fd88a9784"
        target="_blank">FAQs</a>.
</div>
<div class="row justify-content-center p-5 col-6 offset-3"><span class="text-center">
        If you have a question that cannot be answered using the website or FAQs, then please contact us at <a
            class="mr-1 ml-1" href="tel:8662332290">866.233.2290</a> or <a class="ml-1"
            *ngIf="accountService.getCustomerSegmentId() === customerSegment.MarketRate"
            href="mailto: CleanEnergyConnection@duke-energy.com">CleanEnergyConnection@duke-energy.com</a><a
            class="ml-1" *ngIf="accountService.getCustomerSegmentId() === customerSegment.IncomeQualified"
            href="mailto: CECQualified@duke-energy.com">CECQualified@duke-energy.com</a>.</span>
</div>
<de-footer [showSlogan]="false"></de-footer>