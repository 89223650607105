<div class="row progressbar">
    <div class="container">
        <div class="row align-items-center">
            <div class="col text-center">
                <div class="dot" [ngClass]="{'bluedot': currentPage == 1,'greendot': currentPage > 1}"
                    (click)="onDotClick(1)"></div>
                <div class="dottext">Verify Eligibility</div>
            </div>
            <div class="col text-center">
                <div class="dot" [ngClass]="{'bluedot': currentPage == 2,'greendot': currentPage > 2, 'greydot':currentPage < 2}"
                    (click)="onDotClick(2)"></div>
                <div class="dottext">Verified Accounts</div>
            </div>
            <div class="col text-center">
                <div class="dot" [ngClass]="{'bluedot':currentPage == 3,'greendot':currentPage > 3, 'greydot':currentPage < 3}"
                    (click)="onDotClick(3)"></div>
                <div class="dottext">Subscription Size</div>
            </div>
            <div class="col text-center">
                <div class="dot" [ngClass]="{'bluedot':currentPage == 4,'greendot':currentPage > 4, 'greydot':currentPage < 4}"
                    (click)="onDotClick(4)"></div>
                <div class="dottext">Details & Disclosures</div>
            </div>
            <div class="col text-center">
                <div class="dot" [ngClass]="{'greydot':currentPage < 5,'bluedot':currentPage == 5}"></div>
                <div class="dottext">Overview</div>
            </div> 
        </div>
    </div>
</div>
