import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Pipe({
  name: 'maskAccountNumberPipe',
})
export class MaskAccountNumberPipe implements PipeTransform {
  constructor(public authService: AuthenticationService) {
  }
  // The purpose of this pipe is to only show the last 4 characters of an account number
  transform(value: string): string {
    if (this.authService.userRole !== 'cust') {
      return value.replace(/\d(?=\d{4})/g, '*');
    }
    return value;
  }
}
