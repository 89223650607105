import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AccountService } from 'src/app/services';
import { Alert } from 'src/app/modules/enrollment/models/data/alert.model';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertMessageDialogComponent } from '../alert-message-dialog/alert-message-dialog.component';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    accountNumber: string;
    allAlerts: any[] = [];
    allAvaliableAlerts: any[] = [];
    alerts: any[] = [];
    pageSize = 10;
    numOfItems = 0;
    pageIndex = 0;
    endDate = new Date();
    startDate = new Date();
    alertForm: FormGroup = new FormGroup({
        fromDate: new FormControl(''),
        toDate: new FormControl(''),
        alertFilter: new FormControl('')
      });
    public alertDialogRef: MatDialogRef<AlertMessageDialogComponent>;

    constructor(public accountSvc: AccountService,
        private windowRef: WindowReferenceService,
        public dialog: MatDialog) {
        this.startDate.setFullYear(this.startDate.getFullYear() - 1);
    }

    ngOnInit() {
        this.accountNumber = this.accountSvc.getSelectedAccountNumber();
        this.paginator.page.subscribe(x => {
            this.pageIndex = x.pageIndex;
            let beginingItem = this.pageIndex * this.pageSize;
            this.alerts = this.allAvaliableAlerts.slice(beginingItem, (beginingItem + this.pageSize));
          });
        this.alertForm.get('fromDate').valueChanges.subscribe(() => {
            this.filterByDateAndTitle();
        });
        this.alertForm.get('toDate').valueChanges.subscribe( () => {
            this.filterByDateAndTitle();
        });
        this.alertForm.get('alertFilter').valueChanges.subscribe( () => {
            this.filterByDateAndTitle();
        });
        this.getAlerts();
        this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':'/vpv/cec/solar-subscription/alerts/load'});
    }

    filterByDateAndTitle() {
        this.pageIndex = 0;
        let alertsList = this.allAlerts;
        let fromDate = this.alertForm.get('fromDate').value;
        let toDate = this.alertForm.get('toDate').value;
        let searchFilter = this.alertForm.get('alertFilter').value;
        if (fromDate) { //if From Date Is not inputed
           alertsList = alertsList.filter(x => new Date(x.created_at) >= fromDate);
        } 
        if (toDate) { // if just from date is being filtered
            alertsList = alertsList.filter(x => new Date(x.created_at) <= toDate);
        }
        if (searchFilter) {
            alertsList = alertsList.filter(x => x.alert_type.toLocaleLowerCase().includes(searchFilter) || x.alert_message.toLocaleLowerCase().includes(searchFilter));
        }
        this.allAvaliableAlerts = alertsList;
        this.alerts =  this.allAvaliableAlerts.slice(0, this.pageSize);
        this.numOfItems = this.alerts.length; 
    }

    getAlerts() {
        this.accountSvc.getMessages(this.accountNumber).subscribe(al => {
            this.allAlerts = al;
            this.allAvaliableAlerts = this.allAlerts;
            this.alerts = this.allAlerts.slice(0, this.pageSize);
            this.numOfItems = this.alerts.length;
        } );
    }

    onAlertSelection(created_date, alert_type, alert_message) {
        this.pushOnViewAlertGA();
        this.showAlertDialoga(created_date,alert_type,alert_message);
    }

    pushOnViewAlertGA(){
        this.windowRef.nativeWindow.dataLayer.push({
            'event-category':'solar-subscription_alerts',
            'event-action':'clickthrough',
            'event-label':'view-alert',
            'event':'event-click'
          });
      }

    showAlertDialoga(alertDate: Date, alertType: string, alertMessage: string) {
        this.alertDialogRef = this.dialog.open(AlertMessageDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
              alertDate: alertDate,
              alertTitle: alertType + ' Alert',
              alertMessage: alertMessage
            }
        });
    }
}
