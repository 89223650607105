<div class="container">
    <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center p-3">
        <p>To get started, sign in with your MyAccount email and password.</p>
        <p>If you have questions please email us directly at
            <a href="mailto: CleanEnergyConnection@duke-energy.com">CleanEnergyConnection@duke-energy.com<br></a>
            with your name, address, account number and a good contact number so we can follow-up with you within 2
            business days.
        </p>
        <div class="de-login-wrapper">
            <de-login #loginComponent [showRegisterHelpLinks]="false" [loginTitle]="''"
                [usernamePlaceholder]="'Username'" (login)="loginUser($event)"></de-login>
            <!-- <span style="color: red; margin-top: 1%" *ngIf="loginError!=''">{{loginError}}</span> -->
        </div>
        <div class="w-100 text-center">
            <!-- <a class="pr-5 text-nowrap fs-link"
                href="https://www.duke-energy.com/forgot-username"
                target="_blank">Forgot Username</a>
            <a class="pl-5 text-nowrap fs-link" href="https://p-auth.duke-energy.com/my-account/sign-in/forgot-password"
                target="_blank">Forgot
                Password</a> -->
            <a class="text-nowrap fs-link" href="https://www.duke-energy.com/need-help-sign-in" target="_blank">
                Need help signing in? </a>
        </div>
    </div>
</div>