<form [formGroup]="monthlyBreakdownForm" novalidate>
    <div class="align-center">
        <mat-form-field class="filter" floatLabel="never">
            <mat-label class="main-label">Select Year</mat-label>
            <mat-select formControlName="year" class="long">
                <mat-option *ngFor="let yearItem of years; let i = index" [(value)]="years[i].value">
                    {{yearItem.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
</div>
    <div class="container background-white">
        <div class="table-wrapper">
            <table class="account-table">
                <div class="no-gutters header-row no-wrap">
                    <div class="col header-text pl-4">
                    </div>
                    <div class="col over-view-header-text">Electric Bill History</div>
                    <div class="col header-text"></div>
                    <div class="col header-text white-bottom-header">&nbsp;</div>
                    <div class="col over-view-header-text no-wrap"><b>Solar Generation Estimates</b></div>
                    <div class="col header-text"></div>
                    <div class="col header-text"></div>
                    <div class="col header-text white-bottom-header">&nbsp;</div>
                    <div class="col header-text"></div>
                </div>
                <div class="no-gutters account-row">
                    <div class="col header-text pl-4">
                        <b>Date</b>
                    </div>
                    <div class="col header-text"><b>Energy Usage (kWh)</b></div>
                    <div class="col header-text"><b>Electric Bill</b></div>
                    <div class="col header-text white-bottom-header">&nbsp;</div>
                    <div class="col header-text"><b>Solar Generation (kWh)</b></div>
                    <div class="col header-text"><b>Subscription Fee</b></div>
                    <div class="col header-text"><b>Clean Energy Connection Credit</b></div>
                    <div class="col header-text white-bottom-header">&nbsp;</div>
                    <div class="col header-text"><b>Credit After Fees</b></div>
                </div>
                <ng-container *ngFor="let bill of bills; let i = index">
                    <div class="no-gutters account-row align-right">
                        <div class="col d-flex flex-row">
                            <p style="padding-left: 10px;">{{bill.bill_date | date }}</p>
                        </div>
                        <div class="col">{{bill.energy.usage_kwh}}</div>
                        <div class="col">{{bill.dollars.electric_usage_charge| currency}}</div>
                        <div class="col white-bottom">&nbsp;</div>
                        <div class="col">{{bill.energy.solar_generation_kwh| number : '1.2-2'}}</div>
                        <div class="col">{{bill.dollars.fees| currency}}</div>
                        <div class="col">{{bill.dollars.credits| currency}}</div>
                        <div class="col white-bottom">&nbsp;</div>
                        <div class="col green-text">{{bill.dollars.savings| currency}}</div>
                    </div>
                </ng-container>
                    <div class="no-gutters account-row-bottom align-right">
                        <div class="col d-flex flex-row">
                            <p style="padding-left: 10px;"></p>
                        </div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col white-bottom">&nbsp;</div>
                        <div class="col"></div>
                        <div class="col"> <div class="col ml-2">{{totalFees| currency}}</div></div>
                        <div class="col"><div class="row"> <div class="col">-</div><div class="col-2 mr-2">{{totalCredits| currency}}</div></div></div>
                        <div class="col white-bottom">&nbsp;</div>
                        <div class="col green-text"></div>
                    </div>
                    <div class="no-gutters account-row-bottom">
                        <div class="col d-flex flex-row">
                            <p style="padding-left: 10px;"></p>
                        </div>
                        <div class="col color-light-blue">Annual Electric Bill =</div>
                        <div class="col color-light-blue">{{totalBill| currency}}</div>
                        <div class="col white-bottom">&nbsp;</div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col green-text">Net Annual Savings =</div>
                        <div class="col green-text">{{this.totalSavings| currency}}</div>
                    </div>
            </table>
        </div>
    </div>
    <div class="container pager">
    </div>

</form>