import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AccountService, ApiService } from 'src/app/services';
import { CustomerSegment } from 'src/app/modules/shared/models/enum.model';

@Component({
  selector: 'app-savings-calculator',
  templateUrl: './savings-calculator.component.html',
  styleUrls: ['./savings-calculator.component.scss']
})
export class SavingsCalculatorComponent implements OnInit {
  @Input() startingKw;
  @Input() maxKw;
  @Output() saveEvent = new EventEmitter();
  calculatorForm: FormGroup = null;
  monthlySubscriptionFee = 0.00;
  trueMaxKw = null;
  subscriptionSize = 0;
  avgMonthlyNetSavingsFirstYear = 0;
  avgMonthlyNetSavingsFifthYear = 0;
  avgMonthlyNetSavingsTenthYear = 0;
  maxSubscriptionSize = 0;
  sliderLoaded = false;
  monthlyCreditFirstYear = 0;
  monthlyCreditFifthYear = 0;
  monthlyCreditTenthYear = 0;
  lastCalculated: any = null;
  customerSegmentId = CustomerSegment.MarketRate + "";
  isMobile = false;
  public get customerSegment(): typeof CustomerSegment {
    return CustomerSegment; 
  }


  constructor(
    public apiGateway: ApiService,
    public accountService: AccountService
  ) {


  }

  ngOnInit() {
    this.isMobile =  window.innerWidth < 601;
    this.customerSegmentId = this.accountService.getCustomerSegmentId();
    this.accountService.setCurrentCalculatorSize(null);
    this.setForm();
    this.subscriptionSize = this.startingKw;
    this.maxSubscriptionSize = this.maxKw ? this.maxKw : this.subscriptionSize;
    this.trueMaxKw = this.maxSubscriptionSize;
    this.sliderLoaded = true;
    this.calculateValues();
    this.calculatorForm.get('kwInput').valueChanges.pipe(debounceTime(50)).subscribe(kwInput => {
      if (!kwInput) {
        return;
      }
      if (kwInput > this.trueMaxKw) {
        kwInput = this.trueMaxKw;
        this.calculatorForm.patchValue( {
          kwInput: kwInput
        });
      }
      if (kwInput < 1) {
        kwInput = 1;
        this.calculatorForm.patchValue( {
          kwInput: kwInput
        });
      }
     });
    this.calculatorForm.get('kwInput').valueChanges.pipe(debounceTime(700)).subscribe(kwInput => {
      if(!kwInput) {
        return;
      }
      if ( kwInput < 1) {
        kwInput = 1;
      }
      if (kwInput > this.trueMaxKw) {
        kwInput = this.trueMaxKw;
      }

      this.sliderLoaded = false;
      this.subscriptionSize = kwInput
      this.maxSubscriptionSize = this.subscriptionSize;
      setTimeout(() => {
        this.sliderLoaded = true;
      },100);
      this.calculateValues();
    })
  }

  onSave() {
    this.saveEvent.emit(this.lastCalculated);
  }

  onSliderChange(kw: number) {
    setTimeout(() => {
      this.calculatorForm.patchValue({
          subscriptionSize: kw,
      });
      this.subscriptionSize = kw;
      this.calculateValues();
  }, 100)

  }

  getTextColor(number: string) {
   if((number + "").includes('-')) {
     return 'red-text';
   }
   return 'blue-text'
  }

  convertDollarsToKw(dollarAmount){
    return dollarAmount * (1/.13762) * (1/204.4)
  }

  calculateValues() {
    this.apiGateway.calculateKwStatsApi(this.subscriptionSize, this.customerSegmentId, "R").subscribe( res => {
      this.lastCalculated = res;
      this.accountService.setCurrentCalculatorSize(this.lastCalculated);
      this.avgMonthlyNetSavingsFirstYear = res.avg_monthly_net_savings_first_year;
      this.avgMonthlyNetSavingsFifthYear = res.avg_monthly_net_savings_fifth_year;
      this.avgMonthlyNetSavingsTenthYear = res.avg_monthly_net_savings_tenth_year;
      this.monthlySubscriptionFee = res.monthly_subscription_fee;
      this.monthlyCreditFirstYear = res.avg_monthly_credit_first_year,
      this.monthlyCreditFifthYear = res.avg_monthly_credit_fifth_year,
      this.monthlyCreditTenthYear = res.avg_monthly_credit_tenth_year
    })
  }

  setForm() {
    this.calculatorForm = new FormGroup({
      kwInput: new FormControl(this.startingKw, [Validators.required, Validators.min(1)])
    });
  }

}

