import { Component, Input, OnInit } from '@angular/core';
import { BillImpactIntervalModel } from '../../../models/data/bill-impact-interval.model';

@Component({
  selector: 'app-bill-impact-interval',
  templateUrl: './bill-impact-interval.component.html',
  styleUrls: ['./bill-impact-interval.component.scss']
})
export class BillImpactIntervalComponent implements OnInit {
  @Input('billImpact') billImpactData: BillImpactIntervalModel;
  @Input('displayMode') displayMode: string;
  @Input('title') title: string;

  constructor() { }
 
  ngOnInit(): void {
  }

}
