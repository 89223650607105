import { Component, OnInit } from '@angular/core';
import { AccountService, ApiService } from 'src/app/services';
import { FormControl, FormGroup } from '@angular/forms';
import { MonthlyBillModel } from '../../models/data/monthly-bill-response.model';

class DropDownSelection {
    name: any;
    value: any;
}

@Component({
    selector: 'app-monthly-breakdown',
    templateUrl: './monthly-breakdown.component.html',
    styleUrls: ['./monthly-breakdown.component.scss']
})

export class MonthlyBreakdownComponent implements OnInit {
    accountNumber: string;
    allBreakdowns: MonthlyBillModel[] = [];
    bills: MonthlyBillModel[] = [];
    endDate = new Date();
    startDate = new Date();
    totalFees = 0;
    totalCredits = 0;
    totalBill = 0;
    totalSavings = 0;
    years = [];
    yearsNumberList = [];
    monthlyBreakdownForm: FormGroup = new FormGroup({
        year: new FormControl(''),
    });

    constructor(public apiSvc: ApiService, public accountSvc: AccountService) {
        this.startDate.setFullYear(this.startDate.getFullYear() - 1);
        this.accountNumber = this.accountSvc.getSelectedAccountNumber();
    }

    ngOnInit() {
        this.accountNumber = this.accountSvc.getSelectedAccountNumber();
        this.monthlyBreakdownForm.get('year').valueChanges.subscribe(() => {
            this.filterByYear();
        });
        this.getMonthlyBreakdown();
    }

    filterByYear() {
        if (!this.monthlyBreakdownForm.get('year').value) {
            this.bills = this.allBreakdowns;
        } else {
            this.bills = this.allBreakdowns.filter(x => x.bill_year === this.monthlyBreakdownForm.get('year').value);
        }
        this.getTotals();
    }

    getMonthlyBreakdown() {
        this.apiSvc.getAccountHistory(this.accountNumber).subscribe(x => {
            this.allBreakdowns = (<any>x).bills;
            this.bills = (<any>x).bills;
            this.getTotals();
            this.getYears();
        })
    }

    getYears() {
        this.years.push(<DropDownSelection>{});
        this.allBreakdowns.forEach(x => {
            if (!(this.yearsNumberList.includes(x.bill_year))) {
                this.years.push(<DropDownSelection>{ name: x.bill_year, value: x.bill_year });
                this.yearsNumberList.push(x.bill_year);
            }
        });
    }

    getTotals() {
        this.totalBill = 0;
        this.totalCredits = 0;
        this.totalFees = 0;
        this.totalSavings = 0;
        this.bills.forEach(x => {
            this.totalCredits += x.dollars.credits;
            this.totalFees += x.dollars.fees;
            this.totalBill += x.dollars.electric_usage_charge;
            this.totalSavings += x.dollars.savings;
        })
    }

}
