import { NgModule, Directive,OnInit, EventEmitter, Output, OnDestroy, Input,ElementRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import { KwSliderComponent } from './modules/shared/kw-slider/kw-slider.component';
import { DocumentTableComponent } from './modules/shared/document-table/document-table.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  imports: [
      CommonModule,
  ],
  declarations: [
    KwSliderComponent,
    DocumentTableComponent
  ],
  exports: [
    KwSliderComponent,
    DocumentTableComponent
  ]
})

export class SharedModule { }
