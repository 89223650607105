<div class="container">
            <div class="panel-header-account-info">
                <p>{{account.account_number}}</p>
                <p>{{account.premise_address.line1}}</p>
                <p class="font-weight-bold">{{account.max_subscription_size_kw}} kW Maximum</p>
            </div>

            <div *ngIf="!panelExpanded" class="panel-header-enrolled-edit-wrapper">
                <a class="edit-button" (click)="openPanel">
                    <span>Edit</span>
                </a>
                <div class="panel-header-enrolled-message">
                    <img src="/assets/greencheck.png">
                    <h2>{{account.current_allocation_size_kw}} kW Enrolled</h2>
                </div>
            </div>

        <div class="panel-details-container">
            <app-savings-calculator (saveEvent)="onSave($event)" [startingKw]="startingkW" [allocationStatus]="allocationStatus"></app-savings-calculator>
        </div>
    </div>
    