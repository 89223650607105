const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_K2xNjSFcU",  
    "aws_user_pools_web_client_id": "2tc8a8re7mnpgdg99ok7ueural", 
    "oauth": {
      "domain": "cec-userpooldomain.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "profile",
        "openid"      
        ],
      "redirectSignIn": "https://cec-customerdashboard.duke-energy.app/",
      "redirectSignOut": "https://cec-customerdashboard.duke-energy.app/",
      "responseType": "token"
    },

    "aws_appsync_graphqlEndpoint": "https://?????????????????????.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
