import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnrollmentSummaryComponent } from 'src/app/modules/enrollment/components/enrollment-summary/enrollment-summary.component';
import { ModifyWaitlistComponent } from 'src/app/modules/enrollment/components/modify-waitlist/modify-waitlist.component';
import { SubscriptionOptionsComponent } from 'src/app/modules/enrollment/components/subscription-options/subscription-options.component';
import { TermsAndConditionsComponent } from 'src/app/modules/enrollment/components/terms-and-conditions/terms-and-conditions.component';
import { VerifiedAccountsComponent } from 'src/app/modules/enrollment/components/verified-accounts/verified-accounts.component';

import { EnrollmentComponent } from './components/enrollment/enrollment.component';
import { IncomeQualifiedComponent } from './components/income-qualified/income-qualified.component';
import { LoginComponent } from './components/login/login.component';
import { SavingsCalculatorComponent } from './components/savings-calculator/savings-calculator.component';
import { UploadSuccessComponent } from './components/upload-success/upload-success.component';

const routes: Routes = [
  { path: '', redirectTo: 'enrollment/login', pathMatch: 'full'},
  { path: 'calculator', component: SavingsCalculatorComponent, pathMatch: 'full'},
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  {path: 'income-qualified',component: IncomeQualifiedComponent, pathMatch: 'full'},
  { path: 'upload-success', component: UploadSuccessComponent, pathMatch: 'full'},
  { path: 'enrollment',
  component: EnrollmentComponent,
  children: [
      {
        path: 'verified-accounts',
        component: VerifiedAccountsComponent,
        //canActivate: [AuthGuard]
      } , {
        path: 'subscription-options',
        component: SubscriptionOptionsComponent,
        //canActivate: [AuthGuard]
      } , {
        path: 'modify-waitlist',
        component: ModifyWaitlistComponent,
        //canActivate: [AuthGuard]
      } , {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        //canActivate: [AuthGuard]
      }, {
        path: 'enrollment-summary',
        component: EnrollmentSummaryComponent,
        //canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnrollmentRoutingModule { }
