import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderLink } from '@de-electron/electron-angular-components';
import { AccountService, ApiService, AuthenticationService } from 'src/app/services';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-customer-portal',
  templateUrl: './customer-portal.component.html',
  styleUrls: ['./customer-portal.component.scss']
})
export class CustomerPortalComponent implements OnInit {

  public headerLinks: HeaderLink[] = [];
  public isMobile = false;
  public siteTitle = 'Clean Energy Connection Dashboard';
  public loggedIn;
  public listOfAccountInformation = [];
  public hasOne;
  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;

  constructor(public router: Router,
    public accountService: AccountService,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private windowRef: WindowReferenceService,
    private ref: ChangeDetectorRef,
    private api: ApiService) {
    this.isMobile = window.innerWidth < 601;
    this.setupHeader();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/customer-portal') {
          this.siteTitle = 'Clean Energy Connection Dashboard';
        } else if (event.urlAfterRedirects === '/customer-portal/reports') {
          this.siteTitle = 'Your Solar Subscription History';
        } else if (event.urlAfterRedirects === '/customer-portal/alerts') {
          this.siteTitle = 'Solar Subscription Alerts';
        } else if (event.urlAfterRedirects === '/customer-portal/edit-subscription') {
          this.siteTitle = 'Modify Clean Energy Connection Subscription';
        } else if (event.urlAfterRedirects === '/customer-portal/documents') {
          this.siteTitle = 'Solar Subscription View Documents';
        } else if (event.urlAfterRedirects === '/customer-portal/bills') {
          this.siteTitle = 'Solar Subscription Monthly Breakdown';
        }
      }
    });

    authService.loggedIn.subscribe(logged => {
      this.loggedIn = logged;
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  ngOnInit() {
    this.api.getAdminActAs('').subscribe(() => { });
    this.loggedIn = this.authService.loggedIn.getValue();
  }

  setupHeader() {
    this.headerLinks = [];
    if (!this.isMobile) {
      this.headerLinks.push({
        viewValue: 'Dashboard',
        value: 'dashboard',
        iconSvgHref: '#homeIconLink'
      });
    }
    this.headerLinks.push(
      {
        viewValue: 'Menu',
        value: 'menu',
        showSubLinks: true,
        subLinks: [
          // {
          //   viewValue: 'Alerts',
          //   value: 'alerts'
          // },
          // {
          //   viewValue: 'Edit Subscription',
          //   value: 'edit-subscription'
          // },
          {
            viewValue: 'View Documents',
            value: 'documents'
          }//,
          // {
          //   viewValue: 'Monthly Breakdown',
          //   value: 'bills'
          // }
        ]
      }
    );
    if (this.isMobile) {
      this.headerLinks[0].subLinks.push({
        viewValue: 'Dashboard',
        value: 'dashboard'
      })
    }
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  onHeaderNav(link: HeaderLink) {
    if (link.value !== 'menu') {
      if (link.value == 'document') {
        this.pushViewDocGA();
      }
      this.setupHeader();
      this.pushMenuPressGA(link.value);
      this.router.navigate(['/customer-portal/' + link.value]);
    }
  }

  pushViewDocGA() {
    this.windowRef.nativeWindow.dataLayer.push({
      'event-category': 'solar-subscription_dashboard',
      'event-action': 'view-documents',
      'event-label': 'view-documents',
      'event': 'event-click'
    });
  }

  pushMenuPressGA(itemPressed) {
    this.windowRef.nativeWindow.dataLayer.push({
      'event-category': 'solar-subscription_menu',
      'event-action': itemPressed,
      'event-label': 'menu',
      'event': 'event-click'
    });
  }

  logOut() {
    this.authService.logout();
  }
}
