<div [ngClass]="panelExpanded ? 'panel-wrapper-expanded' : 'panel-wrapper-collapsed'">
    <mat-expansion-panel #expansionPanel [hideToggle]="true" (opened)="onPanelOpened()" (closed)="onPanelClosed()">
        <mat-expansion-panel-header [collapsedHeight]="'75px'" >
            <div class="panel-header-account-info">
                <p>{{account.account_number}}</p>
                <p>{{account.premise_address.line1}}</p>
                <p class="font-weight-bold">{{account.max_subscription_size_kw}} kW Maximum</p>
            </div>

            <div *ngIf="!panelExpanded" class="panel-header-enrolled-edit-wrapper">
                <a class="edit-button" (click)="openPanel">
                    <span>Edit</span>
                </a>
                <div class="panel-header-enrolled-message">
                    <img src="/assets/greencheck.png">
                    <h2>{{account.current_allocation_size_kw}} kW Enrolled</h2>
                </div>
            </div>
        </mat-expansion-panel-header>
        <div class="panel-details-container">
            <app-savings-calculator *ngIf="panelExpanded" (saveEvent)="onSave($event)"[maxKw]="maxKw" [startingKw]="startingkW"></app-savings-calculator>
        </div>
    </mat-expansion-panel>
    
</div>


