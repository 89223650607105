import { Injectable} from '@angular/core';
import { DeStep } from '@de-electron/electron-angular-components/lib/de-stepper/de-step.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  public stepper: any;

  public steps: DeStep[] = [{
    id: 1,
    title: 'Verify Accounts'
  } , {
    id: 2,
    title: 'Subscription Options'
  } , {
    id: 3,
    title: 'Terms and Conditions'
  } , {
    id: 4,
    title: 'Summary'
  }];

  public activeStep = new BehaviorSubject(1);
  public activeIncrement = new BehaviorSubject(0);

  constructor() {
  }

  getActiveStep(): Observable<number> {
    return this.activeStep.asObservable();
  }

  setActiveStep(step): void {
    this.activeStep.next(step);
  }

  getActiveIncrement(): Observable<number> {
    return this.activeIncrement.asObservable();
  }

  setActiveIncrement(increment): void {
    this.activeIncrement.next(increment);
    if (this.stepper) {
      this.stepper.steps[this.activeStep.getValue() - 1].increments.currentIncrement = increment;
      this.stepper.stepProgress = this.stepper.getStepProgress(this.stepper.steps[this.activeStep.getValue() - 1]);
    }
  }

  incrementStep(): void {
    this.stepper.incrementStep();
  }

  decrementStep(): void {
    this.stepper.decrementStep();
  }
}
