import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AccountService, ApiService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AllocationStatus } from 'src/app/modules/shared/models/enum.model';

@Component({
  selector: 'app-savings-calculator',
  templateUrl: './savings-calculator.component.html',
  styleUrls: ['./savings-calculator.component.scss']
})
export class SavingsCalculatorComponent implements OnInit, OnChanges {
  @ViewChild('kwSlider') kwSlider;
  @ViewChild('subInput') subInput;
  @Input() startingKw;
  @Input() allocationStatus;
  @Output() saveEvent = new EventEmitter();
  calculatorForm: FormGroup = null;
  monthlySubscriptionFee = 0.00;
  subscriptionSize = 0;
  monthlyBillAmount = 150;
  avgMonthlyNetSavingsFirstYear = 0;
  avgMonthlyNetSavingsFifthYear = 0;
  avgMonthlyNetSavingsTenthYear = 0;
  minSubscriptionSize = 1;
  maxSubscriptionSize = 0;
  sliderLoaded = false;
  monthlyCreditFirstYear = 0;
  monthlyCreditFifthYear = 0;
  monthlyCreditTenthYear = 0;
  lastCalculated: any = null;
  customerSegmentId = "2";
  totalCurrentSubscription: number;
  possibleAdditionSubscription = 0;


  constructor(
    public dialog: MatDialog,
    public apiGateway: ApiService,
    public activeRoute: ActivatedRoute,
    public accountService: AccountService,
    public router: Router
  ) {


  }

  ngOnInit() {
    this.customerSegmentId = this.accountService.getCustomerSegmentId();
    let account = this.accountService.getSelectedAccount();
    this.totalCurrentSubscription = account.current_allocation_size_kw;
    let otherAllocations =  account.allocations.filter(x => x.allocation_id !== this.accountService.getSelectedAllocation().allocation_id);
    let otherAllocationsSubSize = 0;
    otherAllocations.forEach(x => {otherAllocationsSubSize += x.allocation_size_kw;});
    this.possibleAdditionSubscription = account.max_subscription_size_kw - this.totalCurrentSubscription;
    this.maxSubscriptionSize  = this.allocationStatus != AllocationStatus.Enrolled || this.possibleAdditionSubscription > 0 ? (account.max_subscription_size_kw - otherAllocationsSubSize) : this.startingKw;
    this.setForm();
    this.subscriptionSize = this.startingKw;
    this.sliderLoaded = true;
    this.calculateValues();
    this.calculatorForm.get('kwInput').valueChanges.pipe(debounceTime(700)).subscribe(kwInput => {
      if(kwInput > this.maxSubscriptionSize || kwInput < 1) {
        kwInput = this.maxSubscriptionSize;
      }
      this.subscriptionSize = kwInput
      this.kwSlider.setNewValue(this.subscriptionSize);
      this.subInput.nativeElement.value = this.subscriptionSize;
      this.calculateValues();
    })
  }

  ngOnChanges() {
  }

  onSave() {
    this.saveEvent.emit(this.lastCalculated);
  }
  onGoBack() {
    this.router.navigate(['/customer-portal/dashboard']);
  }

  onSliderChange(kw: number) {
    setTimeout(() => {
      this.calculatorForm.patchValue({
          subscriptionSize: kw,
      });
      this.subscriptionSize = kw;
      this.subInput.nativeElement.value = this.subscriptionSize;
      this.calculateValues();
  }, 100)

  }

  getTextColor(number: string) {
   if((number + "").includes('-')) {
     return 'red-text';
   }
   return 'blue-text'
  }

  convertDollarsToKw(dollarAmount){
    return dollarAmount * (1/.13762) * (1/204.4)
  }

  calculateValues() {
    this.apiGateway.calculateKwStatsApi(this.subscriptionSize, this.customerSegmentId, "R").subscribe( res => {
      this.lastCalculated = res;
      this.avgMonthlyNetSavingsFirstYear = res.avg_monthly_net_savings_first_year;
      this.avgMonthlyNetSavingsFifthYear = res.avg_monthly_net_savings_fifth_year;
      this.avgMonthlyNetSavingsTenthYear = res.avg_monthly_net_savings_tenth_year;
      this.monthlySubscriptionFee = res.monthly_subscription_fee;
      this.monthlyCreditFirstYear = res.avg_monthly_credit_first_year,
      this.monthlyCreditFifthYear = res.avg_monthly_credit_fifth_year,
      this.monthlyCreditTenthYear = res.avg_monthly_credit_tenth_year
    })
  }

  setForm() {
    this.calculatorForm = new FormGroup({
      kwInput: new FormControl(this.startingKw, [Validators.required, Validators.min(1)])
    });
  }

  onCalculateCurrentAllocationSize() {
    let size = 0;
    this.accountService.getSelectedAccount().allocations.forEach(allo => {
      if(allo.allocation_status_id != AllocationStatus.Cancelled && allo.allocation_status_id != AllocationStatus.VerificationFailed) {
        size += allo.allocation_size_kw;
      }
    });
    this.totalCurrentSubscription = size;
  }

}
function MAT_DIALOG_DATA(MAT_DIALOG_DATA: any) {
  throw new Error('Function not implemented.');
}

