import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertComponent } from './components/alert/alert.component';
import { CommunityImpactComponent } from './components/community-impact/community-impact.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DocumentComponent } from './components/document/document.component';
import { EditSubscriptionComponent } from './components/edit-subscription/edit-subscription.component';
import { MonthlyBreakdownComponent } from './components/monthly-breakdown/monthly-breakdown.component';
import { ReportComponent } from './components/reports/report.component';
import { LoginComponent } from './components/login/login.component';

import { CustomerPortalComponent } from './customer-portal.component';

const routes: Routes = [{
    path: 'customer-portal',
    component: CustomerPortalComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'dashboard',
        redirectTo: ''
      },
      {
        path: 'documents',
        component: DocumentComponent,
      },
      {
        path: 'reports',
        component: ReportComponent,
      },
      {
        path: 'edit-subscription',
        component: EditSubscriptionComponent,
      },
      {
        path: 'community-impact',
        component: CommunityImpactComponent,
      },
      {
        path: 'alerts',
        component: AlertComponent,
      }, 
      {
        path: 'bills',
        component: MonthlyBreakdownComponent,
      }
    ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes, )],
  exports: [RouterModule]
})
export class CustomerPortalRoutingModule { }
