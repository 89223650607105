import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { OAuthResponse } from '../modules/enrollment/models';
import { AccountService } from './account.service';
import { AppConfig } from '../config/app.config';

import { Auth } from 'aws-amplify';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loginTokenUrl: string = this.appConfig.getConfig('MyAccountTokenUrl');
  enrollmentTokenUrl: string = this.appConfig.getConfig('ApiTokenUrl');
  digitalServicesAuthUrl: string = this.appConfig.getConfig('DigitalServicesAuthUrl');
  myAccountConsumerKey: string = this.appConfig.getConfig('MyAccountConsumerKey');
  myAccountConsumerSecret: string = this.appConfig.getConfig('MyAccountConsumerSecret');
  myAccountAppID: string = this.appConfig.getConfig('MyAccountAppID');
  username: string;
  loggedIn = new BehaviorSubject<boolean>(this.userRole === 'cust');
  loadingTokens$ = new BehaviorSubject<boolean>(true);
  private adminLoggedIn = false;

  get userRole(): string {
    return sessionStorage.getItem('user_role');
  }
  set userRole(role: string) {
    sessionStorage.setItem('user_role', role);
    this.loggedIn.next(role === 'cust');
  }
  

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig) {
      if (!this.userRole) {
        this.userRole = 'guest';
      }
    }

  async loginCust(username: string, password: string) {
    //  if (username && password) {
       let authResponse = undefined;
    //   const headers: HttpHeaders = new HttpHeaders({
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Authorization': 'Basic ' + btoa(this.myAccountConsumerKey + ':' + this.myAccountConsumerSecret),
    //     'ID': this.myAccountAppID
    //   });
    //   const options = { headers: headers };
    //   const body: string = 'grant_type=client_credentials';
    //   authResponse = await this.http.post<CCOauthResponse>(this.loginTokenUrl, body, options).toPromise()
    //     .then(async(res: CCOauthResponse) => {
    //       this.setMyAccountAccessToken(res.access_token);
    //       // Return request for My Account authorization after fetching CCOAuth token
    //       const headers: HttpHeaders = new HttpHeaders({
    //         'Authorization': 'Bearer ' + res.access_token
    //       });
    //       const options = { headers: headers };
    //       const body = {
    //         'SenderId': 'SSC',
    //         "LoginIdentity": username,
    //         "Password": password,
    //         "Action": "authenticate"
    //       }
    //       let authResponse = await this.http.post<CCOauthResponse>(this.digitalServicesAuthUrl, body, options).toPromise()
    //         .then((res: any) => {
    //           if (res.ReturnCode !== 0) {
    //             throw new HttpErrorResponse({ statusText: res.MessageText });
    //           } else {
    //             return res;
    //           }
    //         });
    //       return authResponse;
    //     }).catch(err => {
    //       throw new HttpErrorResponse({ statusText: err });
    //     });
    //   return authResponse;
    // } else { return Promise.reject('No Username and Password Provided') }


    authResponse = await Auth.signIn(username, password)
    return authResponse;
  }

  getAdminLoggedIn() {
    return this.adminLoggedIn;
  }

  setAdminLoggedIn(value: boolean) {
    this.adminLoggedIn = value;
  }

  loginLite(customerSearchCriteria): Promise<any> {
    // return this.accountService.getUserAccountsFromLookup(customerSearchCriteria).toPromise().then(res => {
    //   // Return HTTP Success
    //   return new HttpResponse({status: 200});
    // }).catch(error => {
    //   throw new HttpErrorResponse({error: error});
    // });
    return null;
  }

  setTokenInfo(response: OAuthResponse) {
    this.setApiAccessToken(response.access_token);
    if (this.loadingTokens$.getValue()) {
      this.loadingTokens$.next(false);
    }
  }

  setUserRole(role) {
    this.userRole = role;
  }

  setApiAccessToken(token: string) { sessionStorage.setItem('enrollment_access_token', token); }
  getApiAccessToken(): string { return sessionStorage.getItem('enrollment_access_token'); }
  setMyAccountAccessToken(token: string) { sessionStorage.setItem('my_account_access_token', token); }
  getMyAccountAccessToken(): string { return sessionStorage.getItem('my_account_access_token'); }

  fetchEnrollmentTokens() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.appConfig.getConfig('ApiConsumerKey') + ':' + this.appConfig.getConfig('ApiConsumerSecret'))
    });

    const formData = 'grant_type=client_credentials';
    return this.http.post<OAuthResponse>(this.appConfig.getConfig('ApiTokenUrl'), formData, { headers: headers }).pipe(
      tap((response) => {
          this.setTokenInfo(response);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  logout(): void {
    sessionStorage.clear();
    this.userRole = 'guest';
    Auth.signOut();
    window.location.reload();
  }

  // isAccessValid(): boolean {
  //   return Date.now() < this.getAccessTimeout();
  // }
}
