<div class="breakdown-container">
    <div class="breakdown-requested">
        <p><b>{{requestedAmount}} </b>kW requested</p>
        <ng-container *ngIf="activeEnrollment !== undefined || isWaitlistAllocation !== false || isRSPAllocation !== false || isAwaitingVerificationAllocation !== false">
            <i class="fas fa-angle-double-right fa-2x" style="color: white"></i>
        </ng-container>
    </div>
    <ng-container *ngIf="activeEnrollment">
        <div class="breakdown-subscribed"> 
           <p><b>{{activeAmount}} </b>kW subscribed</p>
           <i class="fas fa-angle-double-right fa-2x" style="color: white"></i>
        </div>
    </ng-container>
    <ng-container *ngIf="isWaitlistAllocation">
        <div class="breakdown-waitlisted">
            <p><b>{{waitlistedAmount}} </b>kW waitlisted</p>
        </div>
    </ng-container>
    <ng-container *ngIf="isRSPAllocation">
        <div class="breakdown-waitlisted">
            <p><b>{{RSPAmount}} </b>kW RSPed</p>
        </div>
    </ng-container>
    <ng-container *ngIf="isAwaitingVerificationAllocation">
        <div class="breakdown-waitlisted">
            <p><b>{{AwaitingVerificationAmount}} </b>kW Awaiting Verification</p>
        </div>
    </ng-container>
</div>