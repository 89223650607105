<de-header [isExternal]="true" [centerLogo]="true"></de-header>
<div class="max-height mt-5 pt-5">
    <div class="to-center mt-5 pt-5">
        <h4> Thank you for submitting your document(s).</h4>
    </div>
    <div class="to-center pt-5 mt-5">
        <h4 class="pt-5 mt-5">Please click CONTINUE to move on to select your accounts.</h4>
    </div>
    <div class="row to-center pt-5">
        <div class="col to-center">
            <de-button type="secondary" size="medium" class="m-auto" (click)="onBack()">
                Previous
            </de-button>
            <de-button type="primary" size="medium" class="m-auto" (click)="onContinue()">
                Continue
            </de-button>
        </div>
    </div>
    <div class="row to-center med-font-size">
        <div class="col to-center">
            <p>Have a question? Click here for the Clean Energy Connection <a href="https://www.duke-energy.com/CEC"
                    target="_blank">FAQs</a>.</p>
        </div>
    </div>
    <div class="row to-center">
        <div class="col pb-5 to-center med-font-size">
            <p>If you have a question that cannot be answered using the website or FAQs, then please contact us at
                <a href="tel:8662332290">866.233.2290</a> or
                <span> <a href="mailto: CECQualified@duke-energy.com">CECQualified@duke-energy.com</a></span>.
            </p>
        </div>
    </div>
</div>
<de-footer [showSlogan]="false"></de-footer>