<form [formGroup]="alertForm" novalidate>
    <div class="container background-white">
        <mat-form-field class="ml-4 mt-3">
            <input matInput [matDatepicker]="fromDate" formControlName="fromDate" placeholder="Show from date">
            <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker #fromDate startView="year" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="ml-4">
            <input matInput [matDatepicker]="toDate" formControlName="toDate" placeholder="Show to date">
            <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
            <mat-datepicker #toDate startView="year" [startAt]="endDate"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="filter ml-4" floatLabel="never">
            <mat-label>Search Titles and Messages</mat-label>
            <input matInput class="long" formControlName="alertFilter">
        </mat-form-field>
        <div class="table-wrapper">
            <table class="account-table">
                <div class="no-gutters header-row">
                    <div class="col header-text pl-4">
                        Date
                    </div>
                    <div class="col header-text">Title</div>
                    <div class="col header-text">Message</div>
                </div>
                <ng-container *ngFor="let alert of alerts; let i = index">
                    <div class="no-gutters account-row" (click)="onAlertSelection(alert.created_date, alert.alert_type, alert.alert_message)">
                        <div class="col d-flex flex-row">
                            <p style="padding-left: 10px;">{{alert.created_at | date }}</p>
                        </div>
                        <div class="col"><b>{{alert.alert_type | titlecase }} Alert</b></div>
                        <div class="col">{{alert.alert_message | titlecase}}</div>
                    </div>
                </ng-container>
            </table>
        </div>
    </div>
    <div class="container pager">
        <mat-paginator [pageSize]="pageSize" [length]="numOfItems" [pageIndex]="pageIndex"></mat-paginator>
    </div>

</form>