const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tA6JwdKmK",  
    "aws_user_pools_web_client_id": "ra3efi2vfso0ti1anaigqk9dr", 
    "oauth": {
      "domain": "cec-userpooldomain-qa.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "profile",
        "openid"      
        ],
      "redirectSignIn": "https://cec-customerdashboard-qa.duke-energy.app/",
      "redirectSignOut": "https://cec-customerdashboard-qa.duke-energy.app/",
      "responseType": "token"
    },

    "aws_appsync_graphqlEndpoint": "https://NOT USING GRAPHQL IN CUSTOMER.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
