<span class="close" (click)="closeModal()">&times;</span>
<div class="title">
    <h2>Ineligible Account</h2>
</div>
<table class="account-table">
    <div class="no-gutters header-row">
        <div class="col header-text">Account Number</div>
        <div class="col-3 header-text">Service Address</div>
        <div class="col-2 header-text">City</div>
        <div class="col header-text text-right">Max Subscription</div>
        <div class="col-2 header-text text-center">Eligible</div>
    </div>
    <div class="no-gutters" class="account-row">
        <div class="col d-flex flex-row">
            <ng-container *ngIf="account.account_type === 'R'">
                <img src="../../../assets/home.png">
            </ng-container>
            <ng-container *ngIf="account.account_type === 'N'">
                <img src="../../../assets/business.png">
            </ng-container>
            <p style="padding-left: 10px;">{{account.account_number}}</p>
        </div>
        <div class="col-3">{{account.premise_address.line1 | titlecase }}</div>
        <div class="col-2">{{account.premise_address.city | titlecase}}</div>
        <div class="col max-sub-col text-right">{{account.max_subscription_size_kw}}kW</div>
        <div class="col-2 text-center">
            <img src="../../../assets/greencheck.png" *ngIf="data.account.eligibility_status === 'Eligible'"/>
            <img src="../../../assets/redx.png" *ngIf="data.account.eligibility_status !== 'Eligible'"/>
        </div>
    </div>
</table>
<div class="title">
    <h2>Ineligibility Reasons</h2>
</div>
<br>
<div *ngFor="let reason of data.account.eligibility.ineligibility_reasons" class="row no-gutters align-items-center reason">
    <p><span class="dot"></span>{{reason}}</p>
    <br>
</div>
