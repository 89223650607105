<div class="container">
    <div class="step-description">
        <h1 *ngIf="isInRSP">Review and submit your random selection process (RSP) entry</h1>
        <p *ngIf="isInRSP">Review the details of your RSP entry carefully and click the CONTINUE button to submit</p>
        <h1 *ngIf="!isInRSP">Review and submit your Clean Energy Connection enrollment request.</h1>
        <p *ngIf="!isInRSP">Review the details of your enrollment request carefully and click the CONTINUE button to submit</p>
    </div>
    <div class="cards-container">
        <ng-container *ngFor = "let subscription of selectedSubscriptions">
            <div class="subscription-card-wrapper">
                <app-solar-subscription-card
                    [cardTitle] = "'Subscription for ' + subscription.associatedAccount.account_number"
                    [customSubscription] = "false"
                    [accountNumber]="subscription.associatedAccount.account_number"
                    [resNonRes]="subscription.associatedAccount.account_type"
                    [metrics] = "subscription.metrics"
                    [account] = "accountService.getSelectedAccount()">
                </app-solar-subscription-card>
            </div>
        </ng-container>
    </div>
    <div class="disclaimers-container">
        <p>
            * Your estimated credit and net savings are based on your selection above. Actual savings may vary based on solar production.
        </p>
    </div>
</div>
<div class="documents-container">
        <div class="documents-heading step-description">
            <h1>Clean Energy Connection Documents</h1>
            To continue, please review and accept the program terms and conditions below.
        </div>
        <app-document-table [showChecks]="false"></app-document-table>
    <div class="checkbox-confirmation">
        <div class="m-auto d-flex flex-row">
            <mat-checkbox class="m-auto" #checkbox (change)='onRead(checkbox.checked)'>
            </mat-checkbox>
            <p class="agreement-disclaimer">I have read and agree that my overview is correct.</p>
        </div>
    </div>
</div>

<div class="buttons-container">
    <de-button (click)="navigateToPrevious()">PREVIOUS</de-button>
    <de-button type="primary" [loading]="isLoading" [disabled]="!this.hasReadTerms" (click)="onSubscribe()">Continue</de-button>
</div>
<span class="cancel-link"  (click)="onCancelClick()">
    Cancel
</span>
<br><br><br>
