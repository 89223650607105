import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-upload-success',
  templateUrl: './upload-success.component.html',
  styleUrls: ['./upload-success.component.scss']
})
export class UploadSuccessComponent implements OnInit {

  constructor(public router: Router, public accountService: AccountService, private windowRef: WindowReferenceService) {
  }

  ngOnInit(): void {
    const customerSegment = this.accountService.getCustomerSegmentId();
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name': `/vpv/cec/income-qualified/enrollment/upload-document/confirmation`});
  }
  
  onBack() {
    this.router.navigate(['/enrollment/income-qualified']);
  }

  onContinue() {
    this.router.navigate(['/enrollment/verified-accounts']);
  }

}
