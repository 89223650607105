import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services';
import { SelectedSubscription } from '../../../models/data/selected-subscription.model';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-account-panel',
  templateUrl: './account-panel.component.html',
  styleUrls: ['./account-panel.component.scss']
})
export class AccountPanelComponent implements OnInit {

  @Input() panelExpanded: boolean;
  @Input() account: any;
  public startingkW = 2;
  public maxKw = 2;

  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  constructor( public accountService: AccountService,
    private windowRef: WindowReferenceService,
    public router: Router) {

     }

  ngOnInit(): void {
    this.startingkW = this.account.max_subscription_size_kw;
    this.maxKw = this.account.max_subscription_size_kw;
  }

  onPanelOpened() {
    this.panelExpanded = true;
    this.pushPanelOpenedToGA();
  }

  onPanelClosed() {
    this.panelExpanded = false;

  }

  openPanel() {
    
    this.expansionPanel.open();
  }

  closePanel() {
    this.expansionPanel.close();
  }

  onSave(event: any) {
  
      // TODO: whole page needs to implement multiple accounts selected and pass on respective account instead of first listed...
      const selectedSubscription: Array<SelectedSubscription> = [{
        associatedAccount: this.account,
        metrics: event,
        allocationId: undefined
      }];
  
      this.accountService.setSelectedSubscriptions(selectedSubscription);
      this.router.navigate(['/enrollment/terms-and-conditions']);
    } 

    tabLoadedGA() {
      const customerSegment = this.accountService.getCustomerSegmentId();
      const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
      this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':`/vpv/cec/${registrationType}/enrollment/subscription-options/calculate-savings-benefits/tab-load`});
    }

  pushPanelOpenedToGA() {
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    this.windowRef.nativeWindow.dataLayer.push({
        'event-category': `cec-${registrationType}-edit`,
        'event-action':'edit',
        'event-label':'subscription-options--enrollment',
        'event':'event-click'
      });      
    }
}
