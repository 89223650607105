import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedSubscription } from '../../models/data/selected-subscription.model';
import { AccountService } from 'src/app/services';

import _ from 'lodash';
import { CustomerDetailAllocation } from '../../models/data/customer-detail-allocation.model';
import { WindowReferenceService } from 'src/app/services/window-reference.service';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  public selectedSubscriptions: Array<SelectedSubscription>;
  public hasReadTerms: boolean;
  public isInRSP = false;
  public isLoading = false;
  public hasClickedRead = false;
  public cameFromCustomerPortal

  constructor(public accountService: AccountService,
    private windowRef: WindowReferenceService,
    public router: Router,
    public route: ActivatedRoute) {
    this.selectedSubscriptions = accountService.getSelectedSubscriptions();
    this.isInRSP = this.accountService.getIsInRSPMode();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['From']) {
        this.cameFromCustomerPortal = true;
      }
    });
    this.pushPageLoadGA()

  }
  onSubscribe() {

    if (!this.hasReadTerms || this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.selectedSubscriptions.forEach((x, i) => {
      if (x.allocationId) {
        this.accountService.updateEnrollment(this.mapToEditEnrollmentRequest(x)).subscribe(enrollAccountResponse => {
          if (enrollAccountResponse.error && enrollAccountResponse.error.message && enrollAccountResponse.error.message.length > 0) {
            this.isLoading = false;
            alert("Error Adding Subscription Please Try Again Later");
            return;
          }
          if (i === this.selectedSubscriptions.length - 1) {

            let tempAccountList = this.accountService.getAccounts();
            if (enrollAccountResponse.allocations && enrollAccountResponse.allocations.length > 0) {
              let accountId = enrollAccountResponse.allocations[0].account_id;
              let tempAccount = (<any[]>tempAccountList).find(ta => {
                return ta.account_number == accountId
              })

              tempAccount.current_allocation_size_kw = enrollAccountResponse.current_allocation_size_kw;
              tempAccount.wait_list_position = enrollAccountResponse.wait_list_position;
              tempAccount.allocations = enrollAccountResponse.allocations;
              let newAccountList = (<any[]>tempAccountList).filter(tal => {
                return tal.account_number != accountId
              });
              newAccountList.push(tempAccount);
              this.accountService.setAccounts(newAccountList);
            }
            if (this.cameFromCustomerPortal) {
              this.router.navigate(['/customer-portal/dashboard'])
              return;
            } else {
              this.router.navigate(['/enrollment/enrollment-summary'], {
                state: {
                  responseData: enrollAccountResponse
                }
              });
            }
          }
          this.isLoading = false;
        });
      } else {
        this.accountService.enrollAccount(this.mapToNewEnrollmentRequest(x)).subscribe(enrollAccountResponse => {
          if (enrollAccountResponse.error && enrollAccountResponse.error.message && enrollAccountResponse.error.message.length > 0) {
            this.isLoading = false;
            this.windowRef.nativeWindow.dataLayer.push({ 
                'event-category':'cec_error', 
                'event-action': `Error during enrollment`,
                'event-label':'enrollment-error', 'event':'event-click' });
            alert("Please contact us at CleanEnergyConnection@duke-energy.com or 866.233.2290 for help with troubleshooting enrollment issues.");
            return;
          }
          if (i === this.selectedSubscriptions.length - 1) {

            let tempAccountList = this.accountService.getAccounts();
            if (enrollAccountResponse.allocations && enrollAccountResponse.allocations.length > 0) {
              let accountId = enrollAccountResponse.allocations[0].account_id;
              let tempAccount = (<any[]>tempAccountList).find(ta => {
                return ta.account_number == accountId
              })

              tempAccount.current_allocation_size_kw = enrollAccountResponse.current_allocation_size_kw;
              tempAccount.wait_list_position = enrollAccountResponse.wait_list_position;
              tempAccount.allocations = enrollAccountResponse.allocations;
              let newAccountList = (<any[]>tempAccountList).filter(tal => {
                return tal.account_number != accountId
              });
              newAccountList.push(tempAccount);
              this.accountService.setAccounts(newAccountList);
            }
            this.router.navigate(['/enrollment/enrollment-summary'], {
              state: {
                responseData: enrollAccountResponse
              }
            });
          }
          this.isLoading = false;
        });
      }
    });
  }

  onRead(isChecked: boolean) {
    this.hasReadTerms = isChecked;
    if (!this.hasClickedRead) {
      this.hasClickedRead = true;
      const customerSegment = this.accountService.getCustomerSegmentId();
      const registrationType = customerSegment == '1' ? 'cec-income-qualified' : (customerSegment == '2' ? 'cec-market-rate' : 'other');
      this.windowRef.nativeWindow.dataLayer.push({
          'event-category': registrationType,
          'event-action': 'clean-energy-connection-terms-conditions',
          'event-label': `terms-conditions-|-${this.selectedSubscriptions[0].allocationId ? 'enrollment-modify' : 'enrollment'}`,
          'event': 'event-click'
        });
    }
  }

  onCancelClick() {
    this.router.navigate(['/enrollment']);
  }

  mapToEditEnrollmentRequest(selectedSubscription: SelectedSubscription) {
    let enrollmentRequest = {
      "input": {
        "account_id": selectedSubscription.associatedAccount.account_number,
        "business_partner_id": selectedSubscription.associatedAccount.business_partner_id,
        "allocation_id": selectedSubscription.allocationId,
        "allocation_size_kw": +(<any>selectedSubscription.metrics).subscription_size_kw,
        "account_notes": selectedSubscription.associatedAccount.account_notes,
        "created_by": "Web-Enroll"
      }
    }
    return enrollmentRequest;
  }

  mapToNewEnrollmentRequest(selectedSubscription: SelectedSubscription) {
    let enrollmentRequest = {
      "input": {
        "account_number": selectedSubscription.associatedAccount.account_number,
        "business_partner_id": selectedSubscription.associatedAccount.business_partner_id,
        "customer_segment_id": this.accountService.getCustomerSegmentId(),
        "allocation_size_kw": +(<any>selectedSubscription.metrics).subscription_size_kw,
        "max_subscription_size_kw": (<any>selectedSubscription.associatedAccount).max_subscription_size_kw,
        "res_non_res": selectedSubscription.associatedAccount.is_residential ? 'R' : 'N',
        "is_verification_required": this.accountService.getCustomerSegmentId() === "1"
          && !selectedSubscription.associatedAccount.low_income.nes_participant
          && selectedSubscription.associatedAccount.low_income.low_income_verification_status !== "VERIFIED" ? true : false,
        "created_by": "Web-Enroll",
        "account_notes": selectedSubscription.associatedAccount.account_notes,
        "enrollment_channel_id": 1,
        "zpart_year": 1,
     	  "zpart_month": 1
      }
    }
    return enrollmentRequest;
  }

  navigateToPrevious() {
    if (this.cameFromCustomerPortal) {
      this.router.navigate(['/customer-portal/edit-subscription'])
      return;
    } else {
      const relevantAccount = this.selectedSubscriptions[0].associatedAccount;
      if (this.selectedSubscriptions[0].allocationId) {
        const waitlistAllocation = _.find(relevantAccount.allocations, (allocation: CustomerDetailAllocation) => {
          return allocation.allocation_id === this.selectedSubscriptions[0].allocationId;
        })
        this.router.navigate(['/enrollment/modify-waitlist'], { queryParams: { id: waitlistAllocation.allocation_id } });
      } else {
        this.router.navigate(['/enrollment/subscription-options'])
      }
    }
  }
  pushPageLoadGA() {
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    this.windowRef.nativeWindow.dataLayer.push({ 'event': 'send-VPV', 'vpv-name': `/vpv/cec/${registrationType}/${this.selectedSubscriptions[0].allocationId ? 'enrollment-modify' : 'enrollment'}/terms-and-conditions/tab-load` });
  }
}
