<div class="table-container">
    <table class="table border-0">
        <div class="header-row row no-gutters">
            <div class="col-md-1">Month/Year</div>
            <div class="col-md-2">Energy Usage (kWH)</div>
            <div class="col-md-1">Electric Bill</div>
            <div class="col-md-2">Solar Generation (kWH)</div>
            <div class="col-md-2">Subscription Fee</div>
            <div class="col-md-2">Clean Energy Connection Credit</div>
            <div class="col-md-2">Credit After Fees</div>
        </div>
        <div>
            <div *ngFor="let creditRow of creditExample; let last = last" class="credit-row row no-gutters" [ngClass]="{'credit-row-last-child': last}">
                <div class="col-md-1">{{creditRow.month_year}}</div>
                <div class="col-md-2">{{creditRow.energy_usage}}</div>
                <div class="col-md-1">${{creditRow.electric_bill}}</div>
                <div class="col-md-2">{{creditRow.solar_generation}}</div>
                <div class="col-md-2">${{creditRow.sub_fee}}</div>
                <div class="col-md-2">${{creditRow.shared_solar_credit}}</div>
                <div class="col-md-2">${{creditRow.credit_after_fees}}</div>
            </div>
            <div class="totals-row row no-gutters">
                <div class="col-md-2">Totals:</div>
                <div class="col-md-2">${{creditTotal}}</div>
                <div class="col-md-2">${{creditAfterFeesTotal}}</div>
            </div>
        </div>
    </table>
</div>

