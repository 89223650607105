<div class="container">
    <div class="step-description">
        <h1 *ngIf="isInRSP" class="center-text">Begin by selecting your account(s) to submit for random selection
            process (RSP).</h1>
        <h1 *ngIf="!isInRSP" class="center-text">Begin by selecting your account(s) to submit for Clean Energy
            Connection</h1>
        <p>Below are your account(s) with their eligibility status<span *ngIf="isInRSP"> for the random selection
                process (RSP)</span> for Clean Energy Connection.</p>
        <p>To begin the entry process, select your eligible account(s). Your maximum subscription amount is based on
            your most recent 12 months of usage.
            If there are less than 12 months of usage, your account(s) will default to maximum
            subscription values. You will select your subscription level during the next enrollment step. If your
            account is ineligible, click the question mark to view more detail. </p>
        <span *ngIf="isMobile">
            <p class="header-text"><b>Scroll right on the table below to view more information and actions</b></p>
        </span>
    </div>
    <div class="">
        <ng-container *ngFor="let account of listOfAccountInformation">
            <div class="card">
                <div class="card-body text-center">
                    <div>
                        <ng-container *ngIf="account.account_type === 'R'">
                            <img width="32" height="32" src="../../../assets/home.png" alt="Residential">
                        </ng-container>
                        <ng-container *ngIf="account.account_type === 'N'">
                            <img width="32" height="32" src="../../../assets/business.png" alt="Business">
                        </ng-container>
                        <strong>Account Number: </strong>
                        {{account.account_number}}
                    </div>
                    <div><strong>Account Holder: </strong>
                        {{account.main_customer.full_name| titlecase}}</div>
                    <div><strong>Service Address: </strong>
                        {{account.premise_address.line1 | titlecase }}</div>
                    <div><strong>City: </strong>
                        {{account.premise_address.city | titlecase}}</div>
                    <div><strong>Max Subscription: </strong>
                        {{account.max_subscription_size_kw}}kW</div>
                    <div class="d-flex align-center justify-content-center">
                        <strong>Eligible: </strong>
                        <img width="32" height="32" src="./assets/greencheck.png"
                            *ngIf="account.eligibility.is_eligible === true" />
                        <img width="32" height="32" src="./assets/redx.png"
                            *ngIf="account.eligibility.is_eligible === false" />
                    </div>
                    <div>
                        <strong>Status: </strong>
                        <span class="pt-1 pl-2 center-text">{{getStatus(account)}}</span>
                    </div>
                    <div class="col text-center" *ngIf="canMakeNewAllocation(account)">
                        <strong> Enroll this account? </strong>
                        <mat-checkbox #checkbox (change)="onAccountCheck(account, checkbox.checked)"
                            [checked]="selectedAccountNumbers.includes(account.account_number)"></mat-checkbox>
                        <i class="fas fa-question-circle d-inline-block" (click)="showIneligibilityReasons(account)"
                            *ngIf="account.eligibility.is_eligible === false"></i>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="account.allocations.length > 0 && !isLoading">
                <ng-container
                    *ngFor="let allocation of account.allocations | filterActiveOrCancelled; first as isFirst">
                    <div class="allocation-row no-gutters" *ngIf="showEdit(allocation.allocation_status_id)">
                        <i class="fas fa-1x fa-caret-down" *ngIf="isFirst"
                            [ngClass]="selectedAccount(account.account_number) ?  'selector-triangle__selected' : 'selector-triangle'"></i>
                        <div class="allocation-row__type">
                            <div class="allocation-row__type__description">
                                <ng-container *ngIf="+allocation.allocation_status_id == 3">
                                    <strong>Waitlisted - Queue Position: {{account.wait_list_position}}</strong>
                                </ng-container>
                                <ng-container *ngIf="+allocation.allocation_status_id == 2">
                                    <strong>Pending</strong>
                                </ng-container>
                                <ng-container *ngIf="+allocation.allocation_status_id == 4">
                                    <strong>RSP Registered</strong>
                                </ng-container>
                                <ng-container *ngIf="+allocation.allocation_status_id == 5">
                                    <strong>Awaiting Verification</strong>
                                </ng-container>
                            </div>
                            <div class="allocation-row__type__breakdown">
                                <app-allocation-breakdown [allocation]="allocation" [account]="account">
                                </app-allocation-breakdown>
                            </div>
                        </div>
                        <div class="allocation-row__info">
                            <div class="allocation-row__info__button">
                                <ng-container
                                    *ngIf="+allocation.allocation_status_id == 4 || +allocation.allocation_status_id == 3 || +allocation.allocation_status_id == 5 ">
                                    <de-button (click)="showCancelEnrollment(allocation, account)">
                                        <p *ngIf="+allocation.allocation_status_id == 3" style="color: red">Cancel
                                            Subscription</p>
                                        <p *ngIf="+allocation.allocation_status_id == 4" style="color: red">Cancel
                                            RSP Registration</p>
                                        <p *ngIf="+allocation.allocation_status_id == 5" style="color: red">Cancel
                                            Entry</p>
                                    </de-button>
                                    <de-button (click)="navigateToModifyWaitlist(account, allocation)">
                                        <p *ngIf="+allocation.allocation_status_id == 3" style="color: #077FAA">
                                            Modify Waitlist Size</p>
                                        <p *ngIf="+allocation.allocation_status_id == 4" style="color: #077FAA">
                                            Modify RSP Entry Size</p>
                                        <p *ngIf="+allocation.allocation_status_id == 5" style="color: #077FAA">
                                            Modify Entry Size</p>
                                    </de-button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div class="text-center">
        <de-button (click)="selectedAccountNumbers.length > 0 ? navigateToSubOptions() : $event.preventDefault()"
            *ngIf="selectedAccountNumbers.length !== 0" type="continue">CONTINUE</de-button>
    </div>
</div>