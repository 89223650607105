<div class="container">
    <div class="table-wrapper">
        <table class="account-table">
            <div class="no-gutters header-row">
                <div *ngIf="showChecks" class="col-2 header-text">Accepted</div>
                <div class="col header-text pl-4">File Name</div>
                <div class="col-2 header-text text-center">Description</div>
            </div>
            <ng-container *ngFor="let document of documentList">
                <div class="no-gutters account-row">
                    <div *ngIf="showChecks" class="col-2 d-flex flex-row">
                        <p style="padding-left: 10px;" class="text-center"><img *ngIf="showChecks"
                                src="../../../assets/greencheck.png" /></p>
                    </div>
                    <div class="col pl-4"> <span><a target="_blank"
                                href="../../../assets/pdfs/cec-terms-and-conditions.pdf">
                                Clean Energy Connection <span *ngIf="isMobile"><br></span> Terms & Conditions</a></span>
                    </div>
                    <div class="col-2 text-center">
                        <i class="fa fa-2x fa-info-circle" title="Terms and Conditions">

                        </i>
                    </div>
                </div>
            </ng-container>
        </table>
    </div>
</div>