import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, ApiService } from 'src/app/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerSegment } from 'src/app/modules/shared/models/enum.model';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-income-qualified',
  templateUrl: './income-qualified.component.html',
  styleUrls: ['./income-qualified.component.scss'],
})

export class IncomeQualifiedComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: ElementRef<HTMLElement>;
   fileToSend: File;
   documentForm: FormGroup = new FormGroup({
    typeOfDocument : new FormControl('', Validators.required),
    personToUse: new FormControl('', Validators.required)
  });
  listOfAccounts: any[];
  isSending = false;
  

  constructor(public accountService: AccountService, 
    public apiService: ApiService,
    private windowRef: WindowReferenceService,
    public router: Router){}

  ngOnInit() {
    this.listOfAccounts = this.accountService.getAccounts();
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':'/vpv/cec/income-qualified/enrollment/upload-document/page-load'});
  }

  onSubmit() {
    if (!this.documentForm.valid || !this.fileToSend || this.isSending) {
      return;
    }
    this.isSending = true;
    let selectedAccount = this.listOfAccounts.find(loa => loa.main_customer.full_name == this.documentForm.get('personToUse').value);
    this.apiService
    .uploadLMIDocument(this.fileToSend, selectedAccount, this.documentForm.get('typeOfDocument').value)
    .subscribe(resp => {
      if ((<any>resp).message == "Successful Upload") {
        this.router.navigate(['/upload-success']);
      } else {
        this.isSending = false;
        this.windowRef.nativeWindow.dataLayer.push({ 
            'event-category':'cec_error', 
            'event-action': `Error uploading document please check the file and try again.`, 
            'event-label':'income-qual-document-error', 'event':'event-click' });
        }
        alert("Error uploading document please check the file and try again.");
    });
  }

  onCancel() {
    location.replace(`/login?customer_segment_id=${+CustomerSegment.IncomeQualified}&is_in_rsp_mode=${this.accountService.getIsInRSPMode() ?? false}`); 
  }

  public changeListener(files: FileList) {
    if (files && files.length > 0) {
       this.fileToSend = files.item(0);
    }
}

  onUploadFile() {
    let el: HTMLElement = this.fileUpload.nativeElement;
    el.click();
}

}
