<div>
    <div class="main-headings">
        <span class="close" (click)="closeModal()">&times;</span>
        <h1>
            {{data.alertTitle}}
        </h1>
        {{data.alertDate | date}}
    </div>
    <div class="statistics-container">
        {{data.alertMessage}}
    </div>
    <div class="row justify-content-center pt-5">
        <de-button type="primary" (click)="closeModal()">OK</de-button>
    </div>
</div>