import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService, ApiService } from 'src/app/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-cancel-enrollment-dialog',
  templateUrl: './cancel-enrollment-dialog.component.html',
  styleUrls: ['./cancel-enrollment-dialog.component.scss']
})
export class CancelEnrollmentDialogComponent implements OnInit {
  [x: string]: any;

  public subscriptionStats: any = null;
  public account: any;
  public allocation: any;
  public cancelLoading = false;
  public waitlistCanceled = false;
  public isLoading = false;
  public isWaitlisted = true;
  public selectedReason = 0;

  constructor(
    private cancelEnrollmentDialogRef: MatDialogRef<CancelEnrollmentDialogComponent>,
    private apiService: ApiService,
    public accountService: AccountService,
    private router: Router,
    private windowRef: WindowReferenceService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.cancelEnrollmentDialogRef.disableClose = true;
    this.account = data.account;
    this.allocation = data.allocation;
    this.isWaitlisted = this.allocation.allocation_status_id == 3;
  }

  ngOnInit(): void {
    setTimeout(x => {
      this.apiService.calculateKwStatsApi(this.allocation.allocation_size_kw, this.account.customer_segment.customer_segment_id, "R").subscribe(res => {
        this.subscriptionStats = res;
        this.avgMonthlyNetSavingsFirstYear = res.avg_monthly_net_savings_first_year;
        this.avgMonthlyNetSavingsFifthYear = res.avg_monthly_net_savings_fifth_year;
        this.avgMonthlyNetSavingsTenthYear = res.avg_monthly_net_savings_tenth_year;
        this.monthlySubscriptionFee = res.monthly_subscription_fee;
        this.monthlyCreditFirstYear = res.avg_monthly_credit_first_year,
          this.monthlyCreditFifthYear = res.avg_monthly_credit_fifth_year,
          this.monthlyCreditTenthYear = res.avg_monthly_credit_tenth_year
        this.isLoading = false;
      })
    }, 300)
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    this.windowRef.nativeWindow.dataLayer.push({ 'event': 'send-VPV', 'vpv-name': `/vpv/cec/${registrationType}/enrollment-cancel/cancel-subscription/page-load` });
  }

  onSelectCancelReason(event: MatSelectChange) {
    this.selectedReason = event.value;
  }

  closeModal() {
    if (this.waitlistCanceled) {
      this.router.navigate(['/enrollment/verified-accounts']);
    }
    this.cancelEnrollmentDialogRef.close();
    location.reload();
  }

  cancelWaitlist() {
    if (!this.cancelLoading) {
      this.cancelLoading = true;
      this.accountService.cancelEnrollment(this.getCancelWailistRequest()).subscribe((resp) => {
        let tempAccountList = this.accountService.getAccounts();
        if (resp.allocations && resp.allocations.length > 0) {
          let accountId = resp.allocations[0].account_id;
          let tempAccount = (<any[]>tempAccountList).find(ta => {
            return ta.account_number == accountId
          })

          tempAccount.current_allocation_size_kw = resp.current_allocation_size_kw;
          tempAccount.wait_list_position = resp.wait_list_position;
          tempAccount.allocations = resp.allocations;
          let newAccountList = (<any[]>tempAccountList).filter(tal => {
            return tal.account_number != accountId
          });
          newAccountList.push(tempAccount);
          this.accountService.setAccounts(newAccountList);
        }
        this.cancelLoading = false;
        this.waitlistCanceled = true;
        const customerSegment = this.accountService.getCustomerSegmentId();
        const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
        this.windowRef.nativeWindow.dataLayer.push({ 'event': 'send-VPV', 'vpv-name': `/vpv/cec/${registrationType}/enrollment-cancel/cancel-subscription/confirmation` });
      });
    }
  }

  getCancelWailistRequest() {
    return {
      "input": {
        "account_id": this.account.account_number,
        "business_partner_id": this.account.business_partner_id,
        "allocation_id": this.allocation.allocation_id,
        "cancel_reason_id": this.selectedReason,
        "account_notes": this.account.account_notes,
        "created_by": "Web-Enroll"
      }
    }
  }
}
