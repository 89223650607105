import { Injectable, Injector} from '@angular/core';
import {
  HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHeaders, HttpEvent,
  HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpSentEvent, HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// tslint:disable-next-line: import-blacklist
//import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../../services';
import { throwError } from 'rxjs';
import { OAuthResponse } from '../models';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private authService: AuthenticationService;
  private isRefreshingToken = false;
  // private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  constructor(
    private http: HttpClient,
    private inj: Injector) {

  }

  // tslint:disable-next-line:max-line-length
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpEvent<any>> {
    // Don't intercept if we are attempting to get our token.  We need to make this call, unintercepted to actually get the token! ;)
    if (true) {
      const headers = new HttpHeaders({
        }).set('Access-Control-Allow-Origin', '*');
        let cloned = req.clone({headers});
      return next.handle(cloned);
    }
    // If we aren't intercepting tokens, continue on...
      // // We are injecting the authentication service, please avoid injecting it the other way as it causes an infinite loop.
      // this.authService = this.inj.get(AuthenticationService);

      // // We are setting the headers, and bearer token on all requests so that the backend can authorize us.
      // const cloned = this.addToken(req, this.authService.getApiAccessToken());
      // return next.handle(cloned)
      // .catch(error => {
      //     if (error instanceof HttpErrorResponse) {
      //         switch ((<HttpErrorResponse>error).status) {
      //         case 400: // BAD REQUEST - Refresh token timed out.
      //             this.handle400Error(error);
      //             break;
      //         case 401: // UNAUTHORIZED - Get new access token. Existing one is expired.
      //             this.handle401Error(req, next);
      //             break;
      //         // KICK user out...
      //     }
      //     return;
      //     } else {
      //         return Observable.throwError(error);
      //     }
      // });
    }

  addToken(req: HttpRequest<any>, token: string) {
    const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set('Content-Type', 'application/json');
    const cloned = req.clone({ headers });
    return cloned;
  }

  // BAD REQUEST - Refresh token timed out.
  handle400Error(error) {
    return this.authService.logout();
  }

  // UNAUTHORIZED - Get new access token. Existing one is expired.
//   handle401Error(req: HttpRequest<any>, next: HttpHandler) {
//     if (!this.isRefreshingToken) {
//         this.isRefreshingToken = true;
//         // Reset here so that the following requests wait until the token comes back from the getNewAccessToken call...
//         this.tokenSubject.next(null);

//         return this.authService.fetchEnrollmentTokens()
//             .switchMap((response: OAuthResponse) => {
//                 if (response) {
//                     this.authService.setTokenInfo(response);
//                     // Yay! We have a new access token.  Let's use this one now...
//                     this.tokenSubject.next(response['access_token']);
//                     return next.handle(this.addToken(req, response['access_token']));
//                 } else {
//                     // If we don't get a new token.  We're in trouble.  Logout the user...
//                     console.log('interceptor response fail: ');
//                     this.authService.logout();
//                 }
//             })
//             .catch(error => {
//                 // If there is an exception getting a new access token, logout the user...
//                 console.log('logging out user: ' + error);
//                 this.authService.logout();
//                 return throwError(error);
//             })
//             .finally(() => {
//                 this.isRefreshingToken = false;
//             }).subscribe();
//     } else {
//         return this.tokenSubject
//             .filter(token => token != null)
//             .take(1)
//             .switchMap(token => {
//                 return next.handle(this.addToken(req, token));
//             });
//     }
// }

}
