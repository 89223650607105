export enum CustomerSegment {
        IncomeQualified = "1",
        MarketRate = "2",
        LocalGovernment = "3",
        LargeCustomer = "4"
      }
      
export enum AllocationStatus {
        Cancelled = 0,
        Enrolled = 1,
        PendingEnrollment = 2,
        Waitlist = 3,
        RSPRegistered = 4,
        AwaitingVerification = 5,
        VerificationFailed = 6
    }
