import { Component, OnInit, Input } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-allocation-breakdown',
  templateUrl: './allocation-breakdown.component.html',
  styleUrls: ['./allocation-breakdown.component.scss']
})
export class AllocationBreakdownComponent implements OnInit {

  @Input() account: any;
  @Input() allocation: any;


  activeEnrollment: boolean;
  requestedAmount: number;
  subscribedAmount: number;
  waitlistedAmount: number;
  AwaitingVerificationAmount: number;
  RSPAmount: number;
  isWaitlistAllocation: boolean;
  isRSPAllocation: boolean;
  isAwaitingVerificationAllocation: boolean;

  constructor() {
  }

  ngOnInit(): void {
    const activeAllocationList = _.filter(this.account.allocations, {allocation_status_description: 'Active'}); 
    this.isRSPAllocation = +this.allocation.allocation_status_id == 4;
    this.isWaitlistAllocation = +this.allocation.allocation_status_id == 3;
    this.isAwaitingVerificationAllocation = +this.allocation.allocation_status_id == 5;
    if (this.isWaitlistAllocation || this.isRSPAllocation || this.isAwaitingVerificationAllocation) {
      if (this.activeEnrollment) {
        activeAllocationList.forEach(allocation => {
          this.subscribedAmount += allocation.allocation_size_kw;
        });
      }
        this.waitlistedAmount = this.allocation.allocation_size_kw;
        this.RSPAmount = this.allocation.allocation_size_kw;
        this.AwaitingVerificationAmount = this.allocation.allocation_size_kw;
    } 
      this.requestedAmount = this.account.current_allocation_size_kw;
  }
}
