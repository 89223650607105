const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_B2VdTUi34",
    "aws_user_pools_web_client_id": "7ngf6ufvg2m3da237397o36cu5",
    "oauth": {
      "domain": "cec-userpooldomain-sbx.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "profile",
        "openid"      
        ],
      "redirectSignIn": "https://cec-customerdashboard-sbx.duke-energy.app/",
      "redirectSignOut": "https://cec-customerdashboard-sbx.duke-energy.app/",
      "responseType": "token"
    },
    "aws_appsync_graphqlEndpoint": "https://cyfcze55xvbzllojtwvn2irpba.appsync-api.us-east-1.amazonaws.com/graphqlhttps://nugekr4mvngt3gploq3pdj6ksu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
