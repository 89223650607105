import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from 'src/app/services';
import { SelectedSubscription } from '../../models/data/selected-subscription.model';
import { SolarSubscriptionCardComponent } from '../solar-subscription-card/solar-subscription-card.component';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-subscription-options',
  templateUrl: './subscription-options.component.html',
  styleUrls: ['./subscription-options.component.scss']
})
export class SubscriptionOptionsComponent implements OnInit {
  public cardsSelectionStatus: Map<SolarSubscriptionCardComponent, BehaviorSubject<boolean>>;
  public selectedAccount: any;
  public continueDisabled = true;

  constructor(public accountService: AccountService, public router: Router, private windowRef: WindowReferenceService) {
    this.selectedAccount = accountService.getSelectedAccount();
  }

  ngOnInit(): void {
    this.pushPageLoadGA();
  }
  navigateTo(route) {
    this.router.navigate([route]);
  }

  onCancelClick() {
    this.router.navigate(['/enrollment']);
  }

  calculateInitialKw(initialKW: number, percentage: number) {
    return Math.round(initialKW * percentage);
  }

  confirmSubscription() {
    const selectedSubscription: Array<SelectedSubscription> = [{
      associatedAccount: this.selectedAccount,
      metrics: this.accountService.getCurrentCalculatorSize(),
      allocationId: undefined
    }];

    this.accountService.setSelectedSubscriptions(selectedSubscription);
  }

  pushPageLoadGA() {
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':`/vpv/cec/${registrationType}/enrollment/subscription-options/select-account/tab-load`});
  }
}