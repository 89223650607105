import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { DeSsoGuard } from '@de/auth';
import { DashboardComponent } from '../customer-portal/components/dashboard/dashboard.component';
import { DocumentComponent } from '../customer-portal/components/document/document.component';
import { ReportComponent } from '../customer-portal/components/reports/report.component';
import { EditSubscriptionComponent } from '../customer-portal/components/edit-subscription/edit-subscription.component';
import { AlertComponent } from '../customer-portal/components/alert/alert.component';
import { MonthlyBreakdownComponent } from '../customer-portal/components/monthly-breakdown/monthly-breakdown.component';
import { CustomerPortalAdminComponent } from './components/customer-portal-admin/customer-portal-admin.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
    {
      path: 'admin',
      component: CustomerPortalAdminComponent,
      //canActivate: [DeSsoGuard],
      children: [
        {
          path: '',
          component: DashboardComponent,
        },
        {
          path: 'dashboard',
          redirectTo: ''
        },
        {
          path: 'documents',
          component: DocumentComponent,
        },
        {
          path: 'reports',
          component: ReportComponent,
        },
        {
          path: 'edit-subscription',
          component: EditSubscriptionComponent,
        },
        {
          path: 'alerts',
          component: AlertComponent,
        }, 
        {
          path: 'bills',
          component: MonthlyBreakdownComponent,
        }
      ]
    },
    {
      path: 'unauthorized',
      component: UnauthorizedComponent
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes, )],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
