import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-message-dialog',
  templateUrl: './alert-message-dialog.component.html',
  styleUrls: ['./alert-message-dialog.component.scss']
})
export class AlertMessageDialogComponent implements OnInit {
  [x: string]: any;


  constructor(
    private alertMessageDialogRef: MatDialogRef<AlertMessageDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.alertMessageDialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.alertMessageDialogRef.close();
  }

}
