import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'enrollment', pathMatch: 'full' },
  { path: 'enrollment', loadChildren: () => import('./modules/enrollment/enrollment.module').then(m => m.EnrollmentModule) },
  { path: 'customer-portal', loadChildren: () => import('./modules/customer-portal/customer-portal.module').then(m => m.CustomerPortalModule) },
  { path: 'calculator', loadChildren: () => import('./modules/calculator/calculator.module').then(m => m.CalculatorModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
