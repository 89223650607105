import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CustomerPortalAdminComponent } from './components/customer-portal-admin/customer-portal-admin.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeButtonModule, DeFooterModule, DeHeaderModule, DeLoaderModule, DeRadioButtonModule, DeSegmentedControlModule, DeSnackbarModule, DeStepperModule } from '@de-electron/electron-angular-components';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxChartsModule } from '@swimlane/ngx-charts';
//import { AngularResizedEventModule } from 'angular-resize-event';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { SharedModule } from 'src/app/shared.module';
import { CustomerPortalRoutingModule } from '../customer-portal/customer-portal-routing.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from 'src/app/services';
import { AuthInterceptor } from '../enrollment/interceptors/token.interceptor';
import { AuthGuard } from '../enrollment/guards/auth.guard';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environments } from 'src/config/env.config';
import { env } from 'src/config/env';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environments[env.env].azure_client_id,
      authority: 'https://login.microsoftonline.com/' +  environments[env.env].azure_tenant,
      redirectUri: environments[env.env]['azure_redirect_uri']
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE , // set to true for IE 11 IPublicClientApplication
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  //protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  //protectedResourceMap.set('https://8o1i93dkda.execute-api.us-east-1.amazonaws.com/beta/', ['api://8f3fc48a-2226-46f9-b755-a638d91e76d0/user_impersonation']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    CustomerPortalAdminComponent,
    UnauthorizedComponent
  ],
  imports: [
   //AngularResizedEventModule,
    CommonModule,
    DeButtonModule,
    DeFooterModule,
    DeHeaderModule,
    DeLoaderModule,
    DeRadioButtonModule,
    DeSegmentedControlModule,
    DeSnackbarModule,
    DeStepperModule,
    FormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatOptionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgxChartsModule,
    PipeModule,
    ReactiveFormsModule,
    SharedModule,
    CustomerPortalRoutingModule
  ],
  providers: [
    AuthenticationService,
    ApiService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
})

export class AdminModule { }

// logging callback for MSAL system config
export function loggerCallback(logLevel, message, piiEnabled) {
  console.warn('From MSAL');
  console.log(message);
}



