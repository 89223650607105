import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AccountService, ApiService, AuthenticationService } from 'src/app/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-cancel-enrollment-dialog',
  templateUrl: './cancel-enrollment-dialog.component.html',
  styleUrls: ['./cancel-enrollment-dialog.component.scss']
})
export class CancelEnrollmentDialogComponent implements OnInit {
  [x: string]: any;

  public subscriptionStats: any = null;
  public account: any;
  public allocation: any;
  public cancelLoading = false;
  public waitlistCanceled = false;
  public isLoading = false;
  public isWaitlisted = true;
  public listOfAccountInformation = [];
  public selectedReason = 0;

  constructor(
    private cancelEnrollmentDialogRef: MatDialogRef<CancelEnrollmentDialogComponent>,
    private apiService: ApiService,
    private windowRef: WindowReferenceService,
    public accountService: AccountService,
    public authService: AuthenticationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.cancelEnrollmentDialogRef.disableClose = true;
    this.account = data.account;
    this.allocation = data.allocation;
    this.isWaitlisted = this.allocation.allocation_status_id == 3;
  }

  ngOnInit(): void {
    setTimeout(x => {
      this.apiService.calculateKwStatsApi(this.allocation.allocation_size_kw, this.account.customer_segment.customer_segment_id, "R").subscribe(res => {
        this.subscriptionStats = res;
        this.avgMonthlyNetSavingsFirstYear = res.avg_monthly_net_savings_first_year;
        this.avgMonthlyNetSavingsFifthYear = res.avg_monthly_net_savings_fifth_year;
        this.avgMonthlyNetSavingsTenthYear = res.avg_monthly_net_savings_tenth_year;
        this.monthlySubscriptionFee = res.monthly_subscription_fee;
        this.monthlyCreditFirstYear = res.avg_monthly_credit_first_year,
          this.monthlyCreditFifthYear = res.avg_monthly_credit_fifth_year,
          this.monthlyCreditTenthYear = res.avg_monthly_credit_tenth_year
        this.isLoading = false;
      })
    }, 300);
  }

  onSelectCancelReason(event: MatSelectChange) {
    this.selectedReason = event.value;
  }

  closeModal() {
    if (this.waitlistCanceled) {
      this.router.navigate(['/enrollment/verified-accounts']);
    }
    this.isLoading = true;
    this.loadUpdatedList();
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  loadUpdatedList() {
    let subscriptionList = [];
    let related_accounts = this.accountService.getRelatedAccounts();
    related_accounts.forEach(x => {
      subscriptionList.push(this.apiService.getCustomerLookup(x, this.accountService.getCustomerSegmentId(), this.accountService.getCDPId()))
    });
    forkJoin(subscriptionList).subscribe(respAccounts => {
      respAccounts.forEach(ra => {
        if (this.listOfAccountInformation.findIndex(loai => { return loai.account_number == (<any>ra).account_number }) < 0) {
          this.listOfAccountInformation.push(ra);
        }
      });
      this.accountService.setAccounts(this.listOfAccountInformation);
      this.authService.setUserRole('cust');
      this.setAccounts();
      this.accountService.enrollmentHasBeenCanceled$.next(true);
      this.cancelEnrollmentDialogRef.close();
    });
  }

  setAccounts() {
    let accountOptions = this.accountService.getAccounts();
    if (accountOptions !== null) {
      let selectedAccount = accountOptions.find(acct => acct.allocations.find(allo => allo.allocation_status_description === 'Enrolled'));
      if (!selectedAccount) {
        this.navigateTo('/enrollment/verified-accounts');
      }
      this.accountService.setCustomerSegmentId(selectedAccount.customer_segment.customer_segment_id);
      this.accountService.setSelectedAccountNumber(selectedAccount.account_number);
      this.navigateTo('/customer-portal/dashboard');
    } else {
      this.router.navigate(['/enrollment']);
    }
  }

  cancelWaitlist() {
    if (!this.cancelLoading) {
      this.cancelLoading = true;
      this.accountService.cancelEnrollment(this.getCancelWailistRequest()).subscribe((resp) => {
        let tempAccountList = this.accountService.getAccounts();
        if (resp.allocations && resp.allocations.length > 0) {
          let accountId = resp.allocations[0].account_id;
          let tempAccount = (<any[]>tempAccountList).find(ta => {
            return ta.account_number == accountId
          })

          tempAccount.current_allocation_size_kw = resp.current_allocation_size_kw;
          tempAccount.wait_list_position = resp.wait_list_position;
          tempAccount.allocations = resp.allocations;
          let newAccountList = (<any[]>tempAccountList).filter(tal => {
            return tal.account_number != accountId
          });
          newAccountList.push(tempAccount);
          this.accountService.setAccounts(newAccountList);
        }
        this.cancelLoading = false;
        this.waitlistCanceled = true;
        this.pushOnCancelGA();
      });
    }
  }

  pushOnCancelGA() {
    this.windowRef.nativeWindow.dataLayer.push({
      'event-category': 'solar-subscription_subscriptions',
      'event-action': 'cancel-enrollment',
      'event-label': 'cancel-waitlist-subscription',
      'event': 'event-click'
    });
  }

  getCancelWailistRequest() {
    return {
      "input": {
        "account_id": this.account.account_number,
        "business_partner_id": this.account.business_partner_id,
        "allocation_id": this.allocation.allocation_id,
        "cancel_reason_id": this.selectedReason,
        "account_notes": this.account.account_notes,
        "created_by": "Web-Enroll"
      }
    }
  }
}
