import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { AppConfig } from '../config/app.config';
import { Apollo } from 'apollo-angular';
import { CUSTOMER_DETAIL } from './graphql';
import { SUBSCRIPTION_CARD } from './graphql';


@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private apiUrl: string;

  constructor(private http: HttpClient, public appConfig: AppConfig, public apollo: Apollo) {
    this.apiUrl = appConfig.getConfig('ApiServiceUrl');
  }

  getCustomerLookup(accountNumber: string, customer_segment_id: string, cpd_id: string): Observable<any> {
    const body = {
      input: {
        account_number: accountNumber,
        customer_segment_id: customer_segment_id,
        cdp_id: cpd_id
      },
    };
    const url =
      this.apiUrl + '/account/lookup-for-customer';
    return this.http.post(url, body);
  }

  getAdminActAs(lanId): Observable<any> {
    const body = {
      input: {
        user_id: lanId
      },
    };
    const url =
      this.apiUrl + '/admin/user-info';
    return this.http.post(url, body);
  }

  login(u: string, p: string) {
    const url = this.apiUrl + `/account/sign-in`;
    let loginRequestObject = { "input": { "u": u, "p": p } }
    return this.http.post<any>(url, loginRequestObject).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('errors')) {
          throw ((res.errors).map(err => err));
        }
        return res;
      })
    );
  }

  getCecAuthAccounts(authToken: string, cecAuth: boolean) {
    const url = this.apiUrl + `/account/sign-in`;
    let authRequestObject = { 
        "input": { 
          "access_token": authToken, 
          "cecAuth": cecAuth 
        } 
      };
    return this.http.post<any>(url, authRequestObject);
  }


  getData() {

    const body = {

      detailed_accounts: {
        account_number: '910057459037',
        date_of_birth: '',
        email: '',
        phone: '',
        res_non_res: 'R',
        is_low_income: 'false',
      },
    };
    const url =
      this.apiUrl + '/customer-lookup';
    return this.http.post(url, body);

  }


  enrollAccount(newEnrollmentRequest: any): Observable<any> {
    const url = this.apiUrl + `/enrollments/new`;
    return this.http.post<any>(url, newEnrollmentRequest).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('errors')) {
          throw ((res.errors).map(err => err));
        }
        return res;
      })
    );
  }

  uploadLMIDocument(file: any, account: any, documentType: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    const url = this.apiUrl + `/business-partner/income-qualified-document`;
    let data = {
      "input":
      {
        "file_name": file.name,
        "business_partner_id": account.business_partner_id,
        "document_type": documentType,
        "last_name": account.main_customer.last_name,
        "first_name": account.main_customer.first_name
      }
    };
    const formData = new FormData();
    formData.set('files', file);
    formData.set('data', JSON.stringify(data));
    return this.http.post(url, formData, { headers: headers });
  }


  editEnrollment(editEnrollmentRequest: any): Observable<any> {
    const url = this.apiUrl + `/enrollments/edit`;
    return this.http.post<any>(url, editEnrollmentRequest).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('errors')) {
          throw ((res.errors).map(err => err));
        }
        return res;
      })
    );
  }

  cancelEnrollment(cancelEnrollmentRequest: any): Observable<any> {
    const url = this.apiUrl + `/enrollments/cancel`;
    return this.http.post<any>(url, cancelEnrollmentRequest).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('errors')) {
          throw ((res.errors).map(err => { return err }));
        }
        return res;
      })
    );
  }

  getCustomerPortalAlerts(customerPortalAlertRequest: any): Observable<any> {
    const url = this.apiUrl + `/account/customer-portal-messages`;
    return this.http.post<any>(url, customerPortalAlertRequest).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('errors')) {
          throw ((res.errors).map(err => { return err }));
        }
        return res;
      })
    );
  }

  getLoginKillSwitchStatus(): Observable<any> {
    const url = this.apiUrl + `/account/login-kill-switch`;
    return this.http.post<any>(url, null).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('errors')) {
          throw ((res.errors).map(err => { return err }));
        }
        return res;
      })
    );
  }

  calculateKwStatsApi(subscription_size_kw, customer_segment_id, res_non_res): Observable<any> {
    let calculatorRequestObj = {
      "input": {
        "subscription_size_kw": +subscription_size_kw,
        "customer_segment_id": customer_segment_id + "",
        "res_non_res": res_non_res
      }
    }
    return this.http.post(this.apiUrl + `/calculator/account-calculator`, calculatorRequestObj);
  }

  calculateKwStats(kWAmount, resNonRes, accountId?) {
    const program = JSON.parse(sessionStorage.getItem('program'));
    const url = this.apiUrl + 'calculations/subscription-card';
    return this.apollo.query({
      query: SUBSCRIPTION_CARD
    })
  }

  getSolarSubscriptionImpact(accountId: String): Observable<any> {
    const body = {
      input: {
        account_id: accountId,
      },
    };
    const url = this.apiUrl + '/account/your-solar-subscription-impact';
    return this.http.post(url, body);
  }

  getHistoricalBilling(accountId: String, dataFormat: String): Observable<any> {
    const body = {
      input: {
        account_id: accountId,
        data_format: dataFormat,
      },
    };
    const url = this.apiUrl + '/billing/historical-billing';
    return this.http.post(url, body);
  }

  getCustomerSegments(): Observable<any> {
    const body = {
      input: {
      },
    };
    const url = this.apiUrl + '/account-data/customer-segments';
    return this.http.post(url, body);
  }

  getBillingImpact(accountId): Observable<any> {
    const body = {
      account_id: accountId
    }
    const url = this.apiUrl + 'billing/impact';
    return this.http.post(url, body);
  }

  getAccountHistory(accountId: string) {
    const body = {
      account_id: accountId
    }
    const url = this.apiUrl + '/billing/customer-portal/dashboard-chart';
    return this.http.post(url, body);
  }

  returnToDecom(accessToken: string){
    const url = 'https://uat.apigee.duke-energy.app/v1/cust/digitalservices/public/token/authorize';
    const params = {
      scope : 'PROFILE_SETTINGS',
      state: 'Login',
      response_type: 'code',
      redirect_url: 'https%3A%2F%2Fuat.apigee.duke-energy.app%2Fv1%2Fcust%2Fdigitalservices%2Fpublic%2Fwasp%2Fredirect',
      access_token: accessToken,
    }
    return this.http.get(url, {params: params});
  }
}
