import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AccountService, ApiService } from 'src/app/services';
import { FormGroup, FormControl } from '@angular/forms';
import { SSCardMetrics } from '../../models/data/ss-card-metrics.model';
import { BehaviorSubject } from 'rxjs';
import { CustomerSegment } from 'src/app/modules/shared/models/enum.model';

const LOWEST_KW_SUBSCRIPTION = 1;

@Component({
  selector: 'app-solar-subscription-card',
  templateUrl: './solar-subscription-card.component.html',
  styleUrls: ['./solar-subscription-card.component.scss']
})
export class SolarSubscriptionCardComponent implements OnInit, AfterViewInit {
    @Input() inputMode: string;
    @Input() cardTitle: string;
    @Input() customSubscription: boolean;
    @Input() maxSubscription: number;
    @Input() accountNumber: number;
    @Input() metrics: any;
    @Input() resNonRes: string;
    @Input() showMaxAndAddress = true;
    @Input() account: any;


    @Output() cardSelected = new EventEmitter();

    private matchInput: ElementRef;
    private subSizeInput: ElementRef;
    private enrollmentFeeInput: ElementRef;
    private optionSelection = 1;
    private monthlyKWFee: number;

    public cardMetrics: SSCardMetrics;
    public lowerBound = '1%';
    public upperBound = '100%';
    public selectedAccount: any;

    public selected = false;
    public loading$ = new BehaviorSubject(true);
    public get customerSegment(): typeof CustomerSegment {
      return CustomerSegment; 
    }

    subscriptionCardForm: FormGroup = new FormGroup({
      matchInput: new FormControl(''),
      subSizeInput: new FormControl(''),
      enrollmentFeeInput: new FormControl('')
    });

  constructor( public apiService: ApiService,
    public accountService: AccountService) {
    }

  ngOnInit() {
    this.selectedAccount = this.account;
    if (this.cardTitle === undefined) {
      this.cardTitle = 'Subscription';
    }
  }

  ngAfterViewInit() {
    if (this.customSubscription) {
      this.matchInput.nativeElement.value = this.subscriptionCardForm.controls['matchInput'].value;
    }

  }

  setNewMetrics(currentSubscriptionSize: number, calculations: any) {
    this.cardMetrics = {
      subscription_size: currentSubscriptionSize,
      app_fee: calculations.fees.application_fee,
      enroll_fee: calculations.fees.enrollment_fee,
      monthly_subscription_fee: calculations.fees.monthly_subscription,
      monthly_estimate: calculations.monthly_est_avg_credit_after_fees.toString(),
      lifetime_savings: calculations.est_lifetime_savings.toString(),
      ten_years_ahead: calculations.est_lifetime_year
    };
  }

  selectSubscription() {
    this.selected = !this.selected;
    this.cardSelected.emit(this.selected);
  }

  getTextColor(number: string) {
    if((number + "").includes('-')) {
      return 'red-text';
    }
    return 'blue-text'
   }

  formatInput(value) {
    value = value.replace(/\D/g, '');
    if (this.optionSelection === 1) {
      if (value > parseInt(this.upperBound, 10)) {
        value = parseInt(this.upperBound, 10);
      }
      this.matchInput.nativeElement.value = value;
      return value;
    } else {
      if (this.inputMode === 'Subscription Size') {
        if (value > this.maxSubscription) {
          value = this.maxSubscription;
        }
        this.subSizeInput.nativeElement.value = value;
        return value;
      } else if (this.inputMode === 'Max Enrollment Fee') {
        if (value > parseInt(this.upperBound.substr(1), 10)) {
          value = parseInt(this.upperBound.substr(1), 10);
        }
        this.enrollmentFeeInput.nativeElement.value = value;
        return value;
      }
    }
  }


  calculateSubSize(subscriptionSize: number) {
    if (subscriptionSize < LOWEST_KW_SUBSCRIPTION) {
      subscriptionSize = LOWEST_KW_SUBSCRIPTION;
      this.subSizeInput.nativeElement.value = LOWEST_KW_SUBSCRIPTION;
    }
    const calculatedEnergyMatch = Math.round((subscriptionSize / this.maxSubscription) * 100);
    this.subscriptionCardForm.controls['subSizeInput'].setValue(subscriptionSize);
    this.subscriptionCardForm.controls['matchInput'].setValue(calculatedEnergyMatch);
  }

  calculateEnrollmentFee(enrollmentFee: number) {
    const lowerBoundNumber = parseInt(this.lowerBound.substr(1), 10);
    if (enrollmentFee < lowerBoundNumber) {
      enrollmentFee = lowerBoundNumber;
    }

    const calculatedKWAmount = Math.floor(enrollmentFee / this.monthlyKWFee);
    const calculatedEnergyMatch = Math.round((calculatedKWAmount / this.maxSubscription) * 100);
    this.subscriptionCardForm.controls['matchInput'].setValue(calculatedEnergyMatch);
    this.subscriptionCardForm.controls['enrollmentFeeInput'].setValue(enrollmentFee);
  }
}
