import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, ApiService } from 'src/app/services';
import { CustomerDetail } from '../../models/data/account-detail.model';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AllocationStatus, CustomerSegment } from 'src/app/modules/shared/models/enum.model';
import { SelectedSubscription } from '../../models/data/selected-subscription.model';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-modify-waitlist',
  templateUrl: './modify-waitlist.component.html',
  styleUrls: ['./modify-waitlist.component.scss']
})
export class ModifyWaitlistComponent implements OnInit {
  @ViewChild('kwSlider') kwSlider;
  @ViewChild('subInput') subInput;

  public account: CustomerDetail;
  public upcomingAllocation;
  public id: string;
  public maxSubSize = 0;
  public effectiveMaxSubSize = 0;
  monthlySubscriptionFee = 0.00;
  subscriptionSize = 0;
  avgMonthlyNetSavingsFirstYear = 0;
  avgMonthlyNetSavingsFifthYear = 0;
  avgMonthlyNetSavingsTenthYear = 0;
  monthlyCreditFirstYear = 0;
  monthlyCreditFifthYear = 0;
  monthlyCreditTenthYear = 0;
  lastCalculated: any = null;
  public get customerSegment(): typeof CustomerSegment {
    return CustomerSegment; 
  }

  public loadingNewStats$ = new BehaviorSubject<boolean>(false);

  constructor(public accountService: AccountService, public apiService: ApiService,
    private windowRef: WindowReferenceService,
    public route: ActivatedRoute, public router: Router) {
    this.account = accountService.getSelectedAccount();

    route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.upcomingAllocation = _.find(this.account.allocations, ['allocation_id', this.id]);
      let internalMaxSubSize = this.account.max_subscription_size_kw;
      this.subscriptionSize = internalMaxSubSize;
      this.account.allocations.forEach(allo => {
         if (allo.allocation_id != this.id && 
          (allo.allocation_status_id !== AllocationStatus.Cancelled && 
            allo.allocation_status_id !== AllocationStatus.VerificationFailed)) {
              // internalMaxSubSize is defaulted above to the calculated max.
              // Let's subtract existing, alive allocations' kW from that to represent what's left...
            internalMaxSubSize = internalMaxSubSize - allo.allocation_size_kw;
         }
      });
      // Set max as larger of leftover max sub or already subscribed kw...
      this.effectiveMaxSubSize = internalMaxSubSize < this.upcomingAllocation.allocation_size_kw ? this.upcomingAllocation.allocation_size_kw : internalMaxSubSize;
      // If pending can't increase...
      this.effectiveMaxSubSize = this.upcomingAllocation.allocation_status_id == AllocationStatus.PendingEnrollment ? this.upcomingAllocation.allocation_size_kw : this.effectiveMaxSubSize;
      this.maxSubSize = internalMaxSubSize;
    });
  }

  ngOnInit(): void {
    this.accountService.setIsInEditMode(true);
    this.tabLoadedGA()
    
    setTimeout(() => {
      this.calculateNewStats(this.effectiveMaxSubSize);
    }, 0);
  }

  calculateNewStats(kW: number) {
    this.loadingNewStats$.next(true);
      this.apiService.calculateKwStatsApi(kW, this.accountService.getCustomerSegmentId(), "R").subscribe( res => {
        this.lastCalculated = res;
        this.subscriptionSize = kW;
        this.avgMonthlyNetSavingsFirstYear = res.avg_monthly_net_savings_first_year;
        this.avgMonthlyNetSavingsFifthYear = res.avg_monthly_net_savings_fifth_year;
        this.avgMonthlyNetSavingsTenthYear = res.avg_monthly_net_savings_tenth_year;
        this.monthlySubscriptionFee = res.monthly_subscription_fee;
        this.monthlyCreditFirstYear = res.avg_monthly_credit_first_year,
        this.monthlyCreditFifthYear = res.avg_monthly_credit_fifth_year,
        this.monthlyCreditTenthYear = res.avg_monthly_credit_tenth_year
      })
       this.loadingNewStats$.next(false);
  }

  getTextColor(number: string) {
    if((number + "").includes('-')) {
      return 'red-text';
    }
    return 'blue-text'
   }

  onInputChange(value) {
    if (value > this.effectiveMaxSubSize) {
      value = this.effectiveMaxSubSize;
    }
    const newVal = value.toString().replace(/\D/g, '');
    this.subInput.nativeElement.value = newVal;
  }

  onInputFocusOut(value) {
    const intValue = parseInt(value, 10) ? parseInt(value, 10) : undefined;
    if (intValue <= this.maxSubSize && intValue !== 0) {
      this.subInput.nativeElement.value = intValue + 'kW';
      this.kwSlider.setNewValue(intValue);
    } else if (intValue === 0 || intValue === undefined) {
      this.subInput.nativeElement.value = 1 + 'kW';
      this.kwSlider.setNewValue(1);
    } else {
      this.subInput.nativeElement.value = this.maxSubSize + 'kW';
      this.kwSlider.setNewValue(this.maxSubSize);
    }
  }

  onInputFocusIn(value) {
    this.subInput.nativeElement.value = value.replace(/\D/g, '');
  }

  changeSubInput(value) {
    this.subInput.nativeElement.value = value + 'kW';
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  onContinue(){

    const selectedSubscription: Array<SelectedSubscription> = [{
      associatedAccount: this.account,
      metrics: this.lastCalculated,
      allocationId: this.upcomingAllocation.allocation_id
    }];

    this.accountService.setSelectedSubscriptions(selectedSubscription);
    this.router.navigate(['/enrollment/terms-and-conditions']);
  }

  tabLoadedGA() {
    const customerSegment = this.accountService.getCustomerSegmentId();
    const registrationType = customerSegment == '1' ? 'income-qualified' : (customerSegment == '2' ? 'market-rate' : 'other');
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':`/vpv/cec/${registrationType}/enrollment-modify/subscription-options/select-account/tab-load`});
  }
}
