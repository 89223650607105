import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../../../services';
import { AppConfig } from 'src/app/config/app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, public accountService: AccountService, public appConfig: AppConfig) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // Conditions to see if user went through previous required steps
      switch (next.routeConfig.path) {
        case 'verified-accounts':
          if (this.accountService.getAccounts() === null) {
            return this.router.createUrlTree(['/enrollment']);
          }
          break;
        case 'subscription-options':
          if (this.accountService.getSelectedAccountNumber() != null) {
            return this.router.createUrlTree(['/enrollment']);
          }
          break;
        case 'terms-and-conditions':
          if (this.accountService.getSelectedSubscriptions() != null) {
            return this.router.createUrlTree(['/enrollment']);
          }
          break;
        case 'enrollment-summary':
            if (this.accountService.getSelectedSubscriptions() != null) {
              return this.router.createUrlTree(['/enrollment']);
            }
            break;
      }
    return true;
  }
}
