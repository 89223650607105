<div class="slider-wrapper" (window:resize)="onResize()" #sliderWrapper>
    <input value="{{sliderValue}}" class="slider" [ngClass]="{ 'slider__disabled' : input.disabled }" type="range" id="sliderOutput" min="{{min}}" max="{{max}}" step="1" #input
        (input)="onSliderChange($event)"
        (change)="onFinalSliderChange()">
    <output class="slider-thumb" for="sliderOutput" #sliderThumb>
        <ng-container *ngIf="!input.disabled">
            <i class="fas fa-caret-left"></i>
            {{sliderValue}}kW
            <i class="fas fa-caret-right"></i>
        </ng-container>
    </output>
    <div class="percentage-labels">
        <div class="percentage-labels__25">
            <p class="m-auto">|</p>
            <p>Min</p>
        </div>
        <div class="percentage-labels__50">
            <p class="m-auto"></p>
            <p></p>
        </div>
        <div class="percentage-labels__100">
            <p class="m-auto">|</p>
            <p>Max</p>
        </div>
    </div>
</div>
