import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService, StepperService } from 'src/app/services';
import { AccountService } from 'src/app/services/account.service';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { CustomerSegment } from 'src/app/modules/shared/models/enum.model';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentComponent implements AfterViewInit, OnInit {
  @ViewChild ('stepper') stepper: ElementRef;

  public get customerSegment(): typeof CustomerSegment {
    return CustomerSegment; 
  }

  public steps;
  public activeStep;
  public activeIncrement;

  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;

  constructor(public stepperService: StepperService,
    public accountService: AccountService,
    public router: Router,
    private ref: ChangeDetectorRef) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/enrollment/verified-accounts') {
          this.stepperService.setActiveStep(1);
        } else if (event.urlAfterRedirects.includes('/enrollment/subscription-options') ||
          event.urlAfterRedirects.includes('/enrollment/modify-waitlist')) {
          this.stepperService.setActiveStep(2);
        } else if (event.urlAfterRedirects === '/enrollment/terms-and-conditions') {
          this.stepperService.setActiveStep(3);
        } else if (event.urlAfterRedirects === '/enrollment/enrollment-summary') {
          this.stepperService.setActiveStep(4);
        }
      }
    });
  }


  ngOnInit() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })

    this.steps = this.stepperService.steps;
    this.stepperService.getActiveStep().subscribe(value => {
      this.activeStep = value;
    });
    this.stepperService.getActiveIncrement().subscribe(value => {
      this.activeIncrement = value;
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  ngAfterViewInit(): void {
    this.stepperService.stepper = this.stepper;
  }
}
