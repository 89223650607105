import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

    public accountNumber: string;
    constructor(public accountSvc: AccountService, private windowRef: WindowReferenceService) {
    }

    ngOnInit() {
        this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':'/vpv/cec/solar-subscription/documents/load'});
    }
}
