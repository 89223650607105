<div class="container">
    <div class="step-description">
        <h1>Modify Your Clean Energy Connection Enrollment Request</h1>
        <p>Use the slider or text entry in the middle column to modify your current Clean Energy Connection subscription. Moving the slider to 100% will modify your subscription to the max available.</p>
    </div>
    <div class="modify-container">
        <div class="subscription-header">
            <div class="col">            
                <b><span>Subscription for {{account.account_number}}</span></b>
            </div>
            <div class="col content-center">
                <div class="row content-center">{{account.premise_address.line1}}</div>
            </div>
            <div class="col">
                <div class="row content-right">{{upcomingAllocation.allocation_size_kw}} kW requested of {{maxSubSize}}kW Max</div>
            </div>
        </div>
        <div class="calculator-container">
            <div class="input-container">
                <div class="input-wrapper">
                    <div class="kw-slider">
                        <div class="kw-slider__heading">
                            <p>Use the slider below to adjust your Clean Energy Connection enrollment request.</p>
                        </div>
                        <div class="kw-slider__wrapper">
                            <app-kw-slider #kwSlider
                                [min]="1"
                                [max]="effectiveMaxSubSize"
                                (inputChange)="[calculateNewStats($event), changeSubInput(kwSlider.sliderValue)]">
                            </app-kw-slider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="results-container">
                <div class="results-columns">
                    <ng-container *ngIf="subscriptionSize !== 0">
                        <div *ngIf="accountService.getCustomerSegmentId() == customerSegment.IncomeQualified" class="row">
                            <div class="col">
                                <div class="row pb-4 pt-5 to-center">
                                    <h3 class="font-bold">Subscription Fee <span class="hidden"><br>{{netSavingsText}}</span></h3>
                                </div>
                                <div class="row to-center blue-text">
                                    <h1><b>${{monthlySubscriptionFee| number : '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-5">
                                    <h5>Monthly</h5>
                                </div>
                            </div>
                            <div class="col left-border mr-2">
                                <div class="row pb-4 pt-5 to-center">
                                    <h3 class="font-bold">Subscription Credit*</h3>
                                </div>
                                <div class="col to-center blue-text">
                                    <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-5">
                                    <h5>Monthly Credit</h5>
                                </div>
                            </div>
                            <div class="col left-border ml-2">
                                <div class="row pb-4 pt-5 to-center">
                                    <h3><span class="font-bold">Net Savings</span><br>{{netSavingsText}}</h3>
                                </div>
                                <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsFirstYear)">
                                    <h1><b>${{avgMonthlyNetSavingsFirstYear| number: '1.2-2'}}</b></h1>
                                </div>`
                                <div class="row to-center pb-5">
                                    <h5>Monthly Net Savings</h5>
                                </div>
                                <div class="row pt-2 to-center mr-5 ml-5">
                                    <h4>You’ll see savings from your first bill as your bill credits are always higher than your subscription fee!</h4>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="accountService.getCustomerSegmentId() == customerSegment.MarketRate" class="row">
                            <div class="col">
                                <div class="row pb-4 pt-5 to-center">
                                    <h3 class="font-bold">Subscription Fee<span class="hidden"><br>{{netSavingsText}}</span></h3>
                                </div>
                                <div class="col to-center blue-text hidden">
                                    <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-3 hidden">
                                    <h5>Average Monthly Credit (1<sup>st</sup> Year)</h5>
                                </div>
                                <div class="row to-center blue-text">
                                    <h1><b>${{monthlySubscriptionFee| number : '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-5">
                                    <h5>Monthly</h5>
                                </div>
                            </div>
                            <div class="col left-border mr-2">
                                <div class="row pb-4 pt-5 to-center">
                                    <h3 class="font-bold">Subscription Credit*<span class="hidden"><br>{{netSavingsText}}</span></h3>
                                </div>
                                <div class="col to-center blue-text">
                                    <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-3">
                                    <h5>Average Monthly Credit (1<sup>st</sup> Year)</h5>
                                </div>
                                <div class="col to-center blue-text">
                                    <h1><b>${{monthlyCreditFifthYear| number : '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-3">
                                    <h5>Average Monthly Credit (5<sup>th</sup> Year)</h5>
                                </div>
                                <div class="col to-center blue-text">
                                    <h1><b>${{monthlyCreditTenthYear| number : '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-3">
                                    <h5>Average Monthly Credit (10<sup>th</sup> Year)</h5>
                                </div>
                                <div class="row pt-2 to-center mr-5 ml-5">
                                    <h4>After 3 years of continuous subscription, your bill credits increase.</h4>
                                </div>
                            </div>
                            <div class="col left-border ml-2">
                                <div class="row pb-4 pt-5 to-center">
                                    <h3><span class="font-bold">Net Savings</span><br>{{netSavingsText}}</h3>
                                </div>
                                <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsFirstYear)">
                                    <h1><b>${{avgMonthlyNetSavingsFirstYear| number: '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-3">
                                    <h5>Average Monthly Net Savings (1<sup>st</sup> Year)</h5>
                                </div>
                                <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsFifthYear)">
                                    <h1><b>${{avgMonthlyNetSavingsFifthYear| number: '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-3">
                                    <h5>Average Monthly Net Savings (5<sup>th</sup> Year)</h5>
                                </div>
                                <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsTenthYear)">
                                    <h1><b>${{avgMonthlyNetSavingsTenthYear| number: '1.2-2'}}</b></h1>
                                </div>
                                <div class="row to-center pb-3">
                                    <h5>Average Monthly Net Savings (10<sup>th</sup> Year)</h5>
                                </div>
                                <div class="row pt-2 to-center mr-5 ml-5">
                                    <h4>The longer you’re subscribed, the more you save as your bill credits increase.</h4>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="subscriptionSize == 0">
                        <de-loader class="w-100 m-auto"></de-loader>
                    </ng-container>
                </div>
            </div>
            <div class="footer-container">
                <div class="footer-headers-wrapper">
                    <p>* Savings are estimated. Solar production and bill credits vary based on changes in weather.</p>
                    <p>** Source: "Greenhouse Gas Equivalences Calculator | US EPA". US EPA, 2020</p>
                </div>
                <div class="enroll-button-wrapper">
                    <div class="m-auto">
                        <de-button (click)="navigateTo('/enrollment/verified-accounts')">Previous</de-button>
                        <de-button type="primary" (click)="onContinue()">Continue</de-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
