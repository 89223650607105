<div *ngIf="billImpactData" class="interval-container">
    <div class="generation-container">
        <div class="interval_title-and-date">
            <p><b>{{title}}</b></p>
            <p>{{billImpactData.bill_interval}}</p>
        </div>
        <ng-container *ngIf="displayMode === 'kWh'">
            <div class="interval_generation">
                <h3 class="putInline">{{billImpactData.solar_generation_kwh | number : '1.2-2'}} kWh</h3>
                <p>Solar Generation</p>
            </div>
            <div class="interval_usage-breakdown">
                <h3>{{billImpactData.usage_kwh | number}} kWh</h3>
                <p>Energy Usage</p>
            </div>
        </ng-container>
        <ng-container *ngIf="displayMode === 'dollars'">
            <div class="interval_savings">
                <h3 class="putInline">{{billImpactData.savings | currency:'USD'}}</h3>
                <p>Net Savings</p>
            </div>
            <div class="interval_usage-breakdown">
                <div class="breakdown">
                    <p class="breakdown-label">Fees</p>
                    <p>{{billImpactData.fees | currency:'USD'}}</p>
                </div>
                <div class="breakdown">
                    <p class="breakdown-label">Credits</p>
                    <p>{{billImpactData.credits | currency:'USD'}}</p>
                </div>
            </div>
        </ng-container>
    </div>
</div>
