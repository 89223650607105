
<div class="container m-auto overflow-x-hidden">
    <!-- TITLE -->
    <div class="row pb-5">
        <div class="col-lg-12">
            <h1 class="to-center main-header">Adjust Slider To Modify Your Subscription Amount</h1>
        </div>
    </div>

    <div class="row pb-5">
        <div class="col-6">
            <form [formGroup]="calculatorForm">
            <div class="row to-center pb-3"><h4>Enter your subscription level (kW)</h4></div>
            <div class="row to-center">
                <h5></h5>&nbsp;<input matInput #subInput type="number" class="inputWidth to-center" formControlName="kwInput"> 
            </div>
            </form>
        </div>
        <div class="col-6">
            <div class="row to-center pb-2"><h4>How much of your estimated energy use do you want to match with solar?</h4></div>
            <div class="row" *ngIf="sliderLoaded">
                <app-kw-slider #kwSlider class="maxWidth" [min]="minSubscriptionSize" [max]="maxSubscriptionSize" (inputChange)="onSliderChange($event)"></app-kw-slider>
            </div>
        </div>
    </div>
    <div class="row pb-5">
        <div class="col-lg-12">
            <h4 class="to-center main-header">*Your estimated credit and net savings are based on your selection above. Actual savings may vary based on solar production.</h4>
        </div>
    </div>
    <div *ngIf="customerSegmentId == '1'" class="row">
        <div class="col">
            <div class="row pb-4 pt-5 to-center">
                <h3 class="font-bold">Subscription Fee <span class="hidden"><br>{{netSavingsText}}</span></h3>
            </div>
            <div class="row to-center blue-text">
                <h1><b>${{monthlySubscriptionFee| number : '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-5">
                <h5>Monthly</h5>
            </div>
        </div>
        <div class="col left-border mr-2">
            <div class="row pb-4 pt-5 to-center">
                <h3 class="font-bold">Subscription Credit* <span class="hidden"><br>{{netSavingsText}}</span></h3>
            </div>
            <div class="col to-center blue-text">
                <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-5">
                <h5>Monthly Credit</h5>
            </div>
        </div>
        <div class="col left-border ml-2">
            <div class="row pb-4 pt-5 to-center">
                <h3><span class="font-bold">Net Savings</span><br>{{netSavingsText}}</h3>
            </div>
            <div class="row to-center blue-text" [ngClass]="getTextColor(avgMonthlyNetSavingsFirstYear)">
                <h1><b>${{avgMonthlyNetSavingsFirstYear| number: '1.2-2'}}</b></h1>
            </div>`
            <div class="row to-center pb-5">
                <h5>Monthly Net Savings</h5>
            </div>
            <div class="row pt-2 to-center mr-5 ml-5">
                <h4>You’ll see savings from your first bill as your bill credits are always higher than your subscription fee!</h4>
            </div>
        </div>
    </div>
    <div *ngIf="customerSegmentId == '2'" class="row">
        <div class="col">
            <div class="row pb-4 pt-5 to-center">
                <h3 class="font-bold">Subscription Fee<span class="hidden"><br>{{netSavingsText}}</span></h3>
            </div>
            <div class="col to-center blue-text hidden">
                <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-3 hidden">
                <h5>Average Monthly Credit (1<sup>st</sup> Year)</h5>
            </div>
            <div class="row to-center blue-text">
                <h1><b>${{monthlySubscriptionFee| number : '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-5">
                <h5>Monthly</h5>
            </div>
        </div>
        <div class="col left-border mr-2">
            <div class="row pb-4 pt-5 to-center">
                <h3 class="font-bold">Subscription Credit*<span class="hidden"><br>{{netSavingsText}}</span></h3>
            </div>
            <div class="col to-center blue-text">
                <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-3">
                <h5>Average Monthly Credit (1<sup>st</sup> Year)</h5>
            </div>
            <div class="col to-center blue-text">
                <h1><b>${{monthlyCreditFifthYear| number : '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-3">
                <h5>Average Monthly Credit (5<sup>th</sup> Year)</h5>
            </div>
            <div class="col to-center blue-text">
                <h1><b>${{monthlyCreditTenthYear| number : '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-3">
                <h5>Average Monthly Credit (10<sup>th</sup> Year)</h5>
            </div>
            <div class="row pt-2 to-center mr-5 ml-5">
                <h4>After 3 years of continuous subscription, your bill credits increase.</h4>
            </div>
        </div>
        <div class="col left-border ml-2">
            <div class="row pb-4 pt-5 to-center">
                <h3><span class="font-bold">Net Savings</span><br>{{netSavingsText}}</h3>
            </div>
            <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsFirstYear)">
                <h1><b>${{avgMonthlyNetSavingsFirstYear| number: '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-3">
                <h5>Average Monthly Net Savings (1<sup>st</sup> Year)</h5>
            </div>
            <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsFifthYear)">
                <h1><b>${{avgMonthlyNetSavingsFifthYear| number: '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-3">
                <h5>Average Monthly Net Savings (5<sup>th</sup> Year)</h5>
            </div>
            <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsTenthYear)">
                <h1><b>${{avgMonthlyNetSavingsTenthYear| number: '1.2-2'}}</b></h1>
            </div>
            <div class="row to-center pb-3">
                <h5>Average Monthly Net Savings (10<sup>th</sup> Year)</h5>
            </div>
            <div class="row pt-2 to-center mr-5 ml-5">
                <h4>The longer you’re subscribed, the more you save as your bill credit increase.</h4>
            </div>
        </div>
    </div>
    <!-- <div class="d-flex mb-2"> -->
        <div class="row to-center">
            <div class="col to-center">
                <de-button type="secondary" size="small" class="m-auto" (click)="onGoBack()">
                    Go Back
                </de-button>
                <de-button type="primary" size="small" class="m-auto" (click)="onSave()">
                    Save
                </de-button>
            </div>
        <!-- </div> -->
    </div>
</div>
