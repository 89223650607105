import { Pipe, PipeTransform } from '@angular/core';
import { CustomerDetailAllocation } from '../modules/enrollment/models/data/customer-detail-allocation.model';

@Pipe({
  name: 'filterActiveOrCancelled'
})
export class FilterActiveOrCancelledPipe implements PipeTransform {
  transform(values: CustomerDetailAllocation[], ...args: any[]): CustomerDetailAllocation[] {
    return values.filter(allocation => {
      return +allocation.allocation_status_id == 2 || +allocation.allocation_status_id == 4 ||
      +allocation.allocation_status_id == 3 || +allocation.allocation_status_id == 5
    }
    );
  }
}
